import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent {
    arr: any[];
    Articles: any;
    HomeUrl: any;
    AdsData: any;
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    scrollInterval: any;
    colors: string[] = ['#FFF']; // Add more colors as needed


    constructor(private viewportScroller: ViewportScroller, public router: Router,
        public generalservice: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }


    ngOnInit() {
        this.GetAllArticles();
    }
    Articlesprofile() {
        debugger
        this.router.navigate(['/Articles']);
    }

    GetAllArticles() {
        debugger
        this.arr = [];
        this.arr.push({
            // RID: this.loginDetails[0].RID
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = "api/Njobs/NJ_Articles_Crud";
        this.generalservice.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Articles = data;
        },
            err => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }
    navigateToArticleDetails(AID: any) {
        debugger
        this.router.navigate(['/Articlesdetails', { AID: AID }]);
    }
}
