import { Component } from '@angular/core';

@Component({
  selector: 'app-careerjobs',
  templateUrl: './careerjobs.component.html',
  styleUrls: ['./careerjobs.component.scss']
})
export class CareerjobsComponent {

}
