<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page" (click)="home()">Home</li>
                    <li class="breadcrumb-item active" aria-current="page">Subscription</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container d-flex justify-content-center">
        <div class="row mt-3 mb-5 mobilee">
            <div class="container">
                <div class="col-lg-12">
                    <div class="card-container1">
                        <div *ngFor="let item of Packagedata">
                            <div class="Packag">
                                <h4><b>{{item.PackageName}}</b></h4>
                                <span class="notecont">{{item.PackageDescription}}</span>
                                <h6><b>{{item.PackageDuration}}</b></h6>
                                <span class="notecont">PRICE:<b>Rs.{{item.PackagePrice}}.00</b></span>
                                <span class="notecont">{{item.PaymentMode}}</span>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
            <hr />
            <div class="container">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-6">
                            <h5>Subscription Detail</h5>
                            <hr />
                            <img class="scanner" src="../../../assets/img/ScanerImag.jpeg" />
                            <div class="notecont"><b>NOTE:</b>Please Enter Your TransctionID For Quick Verification</div>
                            <div>
                                <input class="subscription" [(ngModel)]="TransctionID" placeholder="Enter TransctionID" />&nbsp;
                                <button class="submit" (click)="Subscribedd()">Submit</button>
                            </div>
                        </div>
                        <div class="col-lg-5"></div>
                    </div>
                </div>

            </div>




        </div>

     </div>


</div>
