<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item text-white"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Articles Details</li>
                </ol>
            </nav>
        </div>
    </div><br />
    <div class="container">
        <div class="row" *ngFor="let item of Articles">

            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <div class="image-container">
                    <img src="{{HomeUrl + item.ArticleImg}}" alt="Article Image" />
                </div>
                <h4 class="text-center pt-3 pb-3" >{{ item.ArticleName }}</h4>
                <!--<p>{{ item.Description }}</p>-->
                <div *ngIf="item.Description">
                    <div *ngFor="let line of item.Description.split('\n')">
                        <p *ngIf="line.endsWith(':'); else normalText" class="p_headding">
                            {{ line }}
                        </p>
                        <ng-template #normalText>
                            <p>{{ line }}</p>
                        </ng-template>
                    </div>
                </div>


            </div>

            <div class="col-lg-1"></div>



        </div>
            </div>
        </div>






