import { Component, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';

@Component({
  selector: 'app-articlesdetails',
  templateUrl: './articlesdetails.component.html',
  styleUrls: ['./articlesdetails.component.scss']
})
export class ArticlesdetailsComponent {
    loginDet: any;
    loginDetails: any;
    Articles: any;
    arr: any;
    HomeUrl: any;
    AID: any;
    constructor(public generalService: GeneralService, public route: ActivatedRoute, private http: HttpClient, private fb: FormBuilder, public Rm: ReactiveFormsModule, public router: Router) {
        this.AID = this.route.snapshot.paramMap.get("AID");
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit() {
        this.GetArticles();
    }
    GetArticles() {
        debugger
        this.arr = [];
        this.arr.push({
             AID: this.AID
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '6');
        var url = "api/Njobs/NJ_Articles_Crud";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Articles = data;
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }
}
