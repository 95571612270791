<footer class="footer_area">

    <div class="footer_widget_area">
        <div class="container">
            <div class="row footer_widget_inner">
                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4> Jobs by Function</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(1)">IT Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(2)">No Code Low code Platforms Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(4)">Non IT Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(3)"> Pharma Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(5)">Sales And Services</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a (click)="JobsFilter(6)"> More Jobs</a></li>
                    </ul>
                    <h4>Company</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Terms of Use</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Privacy Policy</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="contact">Contact Us</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="companyinfo">Company Info</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4>  IT Job Skills</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#"> Java Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">SAP Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">.net Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#"> Oracle Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Sql Server Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">QA Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Silverlight Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">SQL Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">All Skills</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4> Pharma Job Skills</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Clinical Research Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Pharmacovigilance Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Regulatory Affairs Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Quality Control Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Production Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">R&D Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Medical Writing Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Biostatistics Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">All Pharma Jobs</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4> Non-IT Job Skills</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Accounting Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Marketing Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Sales Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Human Resources Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Operations Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Logistics Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Administrative Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Customer Support Jobs</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">All Non-IT Jobs</a></li>
                    </ul>
                </div>






                <!--<div class="col-lg-3 col-sm-12 col-md-12 footer-links">
                    <h4 class="follow">Contact Info</h4>
                    <address class="text-white">
                        3rd Floor Sai Arcade, #2-17 & 2-18,
                        Vani Nagar Bus Stop, Opposite Canara Bank,
                        Malkajgiri, Secunderabad, Hyderabad, India- 500047
                        <br /> <a href="tel:9876543210"><i class="fa fa-phone"></i>+91 9876543210</a>
                        <br /><a href="mailto:info@njobs.com"><i class="fa fa-envelope"></i>info&#64;njobs.com</a>
                    </address>

                </div>-->
            </div>
        </div>
        <div class="container mt-5">

            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="copy"> Copyright<sup>©</sup> 2024 | All rights reserved  by Njobs  </div>
                </div>

                <!--<div class="col-lg-6 col-sm-12 text-lg-end text-sm-start">
                    <div class="copy footer1">
                        Powered by  <a href="https://gagri.net/">  <img src="../assets/img/images/GGL.png" class="  footer-logo" alt="Logo"> </a>
                    </div>
                </div>-->
            </div>


        </div>
    </div>


</footer>

<footer class="mobile-footer">


    <ul class="social-icons">

        <li routerLink="/job"><a href="/job"><i class="fa-solid fa-magnifying-glass"></i>  </a><br /> Jobs</li>
        <li  routerLink="/companyinfo"><a href="/companyinfo"><i class="fa-solid fa-users"></i>  </a><br /> Companies</li>
        <li  ><a href=" "><i class="fa-solid fa-user"></i>  </a><br />Post a Job </li>
        <li routerLink="/useraccount">
            <a>
                <i class="fa-solid fa-user rounded-circle" style="border: 1px solid white; padding: 5px;font-size: 16px;"></i>
            </a><br /> Profile
        </li>
    </ul>
</footer> 

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
