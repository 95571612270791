<div class="container ptb-100">
    <div class="row">
        <div class="col-lg-2  m-2">
            <button type="button" (click)="Back()" class="btn btn-danger btn-border-radius waves-effect mb-2 mt-5"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>
        </div>
        <div class="col-lg-12">
            <fieldset class="mt-3 m-2">
                <legend class="text-center">
                    Job Details
                </legend>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="ProductInfo">Job Title<span style="color:red">*</span></label>
                            <input type="text" class="form-control" placeholder="Jobtitle" oninput="this.value = this.value.replace(/[^A-Za-z .]/g, '')" [(ngModel)]="WorkingDomains" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ProductInfo">RequiredPosition<span style="color:red">*</span></label>
                            <input type="text" class="form-control" placeholder="RequiredPosition" oninput="this.value = this.value.replace(/[^0-9]/g, '')" [(ngModel)]="RequiredPosition" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label class="head_txt">Category Name<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle1($event)" dataKey='Id' field="CategoryName"
                                            [(ngModel)]="CategoryName" [dropdown]="true" [size]="30"
                                            placeholder="Search with Category Name" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.CategoryName}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label class="head_txt">Job Type Name<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle2($event)" dataKey='Id' field="TypeName"
                                            [(ngModel)]="TypeName" [dropdown]="true" [size]="30"
                                            placeholder="Search with TypeName" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.TypeName}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label class="head_txt">Languages<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle3($event)" dataKey='Id' field="Languages"
                                            [(ngModel)]="Languages" [dropdown]="true" [size]="30"
                                            placeholder="Search with Languages" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.Languages}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label class="head_txt">Salary Range<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle4($event)" dataKey='Id' field="SalaryRange"
                                            [(ngModel)]="SalaryRange" [dropdown]="true" [size]="30"
                                            placeholder="Search with Salary Range" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.SalaryRange}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <!--<div class="col-lg-6">
        <label class="head_txt">Skills<span style="color:red;">*</span></label>
        <div class="form-group mt-n2">
            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle($event)" dataKey='Id' field="Skills"
                            [(ngModel)]="Skills" [dropdown]="true" [size]="30"
                            placeholder="Search with Role Name" [minLength]="1" [forceSelection]="true">
                <ng-template let-autofillCustomers pTemplate="item">
                    <div class="card">
                        {{autofillCustomers.Skills}}
                    </div>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>-->

                    <div class="col-lg-6">
                        <label class="head_txt">City Name<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle5($event)" dataKey='Id' field="CityName"
                                            [(ngModel)]="CityName" [dropdown]="true" [size]="30"
                                            placeholder="Search with CityName" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.CityName}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label class="head_txt">Experience<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle6($event)" dataKey='Id' field="Experience"
                                            [(ngModel)]="Experience" [dropdown]="true" [size]="30"
                                            placeholder="Search with Experience" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.Experience}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label class="head_txt">Qualification<span style="color:red;">*</span></label>
                        <div class="form-group mt-n2">
                            <p-autoComplete [suggestions]="deptList" (completeMethod)="filterGroupsSingle7($event)" dataKey='Id' field="Qualification"
                                            [(ngModel)]="Qualification" [dropdown]="true" [size]="30"
                                            placeholder="Search with Qualification" [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card">
                                        {{autofillCustomers.Qualification}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="ProductInfo">Job Description</label>
                            <textarea class="form-control" placeholder="JobDescription" [(ngModel)]="JobDescription" rows="4" cols="20"></textarea>
                        </div><br />
                    </div>
                    <div class="col-lg-12">
                        <div class=" modal-footer" style="border-top: 1px solid #ffffff;">
                            <div>
                                <button id="closemodal" type="button" class="btn btn-success" style="margin-left: 600px" (click)="Update(this.PID)">Update</button>
                            </div><br />
                            <!--<div>
                <button id="closemodal" type="button" class="btn btn-danger" (click)="Delete(this.PID)">Delete</button>
            </div>-->

                        </div>
                    </div>
                </div>
                    


            </fieldset>

        </div>
    </div>
</div>
