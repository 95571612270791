<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item text-white"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item text-white"><a class="text-white" href="/CompanyProfile">companies</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">companyinfo</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- click for about and career -->
    <div class="container">
        <div class="row">
            <div class="col-lg-12">

                <div class="tab-wrap">

                    <input type="radio" id="tab1" name="tabGroup2" class="tab" checked>
                    <label for="tab1" style="text-align:center">About US</label>

                    <input type="radio" id="tab2" name="tabGroup2" class="tab">
                    <label for="tab2" style="text-align:center">Careers</label>

                    <div class="tab__content">
                        <div class="row mt-2 me-3">
                            <!--//me-3-->
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-12 border p-3 m-3 rounded-1">
                                <div class="tab-content tabs" *ngFor="let item of Companies">
                                    <!-- Company Detail Tab Content -->
                                    <div role="tabpanel" class="tab-pane fade show"
                                         [class.active]="currentTab === 'companyDetail'"
                                         *ngIf="currentTab === 'companyDetail'" id="companyDetail">

                                        <div class="col-lg-12">
                                            <div class="card shadow-sm">
                                                <div class="card-header bg-white">
                                                    <h5 class="mb-0 text-primary">Company Details</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <!-- Company Logo Column -->
                                                        <div class="col-lg-3 text-center mb-4 mb-lg-0">
                                                            <img [src]="HomeUrl + item.Companylogo"
                                                                 class="img-fluid rounded shadow-sm"
                                                                 style="max-width: 200px;"
                                                                 [alt]="item.CompanyName + ' logo'">
                                                        </div>

                                                        <!-- Company Info Column -->
                                                        <div class="col-lg-9">
                                                            <div class="row">
                                                                <!-- Basic Info -->
                                                                <div class="col-12 mb-4">
                                                                    <h4 class="text-dark mb-3">{{ item.CompanyName }}</h4>
                                                                    <div class="d-flex flex-column gap-2">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-phone-volume text-primary me-2"></i>
                                                                            <span class="text-muted">{{ item.MobileNo }}</span>
                                                                        </div>
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-envelope text-primary me-2"></i>
                                                                            <span class="text-muted">{{ item.Email }}</span>
                                                                        </div>
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa-solid fa-location-dot text-primary me-2"></i>
                                                                            <span class="text-muted">{{ item.Address }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- About Info -->
                                                                <div class="col-12">
                                                                  
                                                                            <h6 class="text-primary mb-3">About Company</h6>
                                                                            <p class="text-muted mb-0">{{ item.AboutInfo }}</p>
                                                                       
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab__content">
                        <div class="row mt-3 ">
                            <div class="col-md-12">
                                <div class="vertical-tab" role="tabpanel">
                                    <div class="row">
                                        <!-- manage job start-->
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 small-screen mb-3">

                                            <div role="tabpanel" class="tab-pane fade show"
                                                 [class.active]="currentTab === 'companyDetail'"
                                                 *ngIf="currentTab === 'companyDetail'" id="companyDetail">

                                                <div class="container mt-4">
                                                    <div class="row">
                                                        <!-- Left Side Job Cards -->
                                                        <div class="col-lg-12 ">
                                                            <!--<h2 class="text-start">30 JOB(S) OPPORTUNITIES</h2>-->
                                                            <!--<p class="text-muted">Displaying 1 to 20</p>-->
                                                            <!-- Job Card 1 -->
                                                            <div class="card" *ngFor="let filter of Postjobs">
                                                                <div class="job-info p-3">

                                                               
                                                                    <h3  >{{ filter.WorkingDomains }}</h3>
                                                                    <hr />


                                                                    <div class="row">
                                                                        <div class="col-lg-8">
                                                                            <p class="company-name">{{ filter.CompanyName }} </p>
                                                                            <!--<p class="company-name">Job Posted on: {{ filter.CreatedDate | date:'short' }} </p>-->
                                                                            <div class="job-meta">
                                                                                <span>Experience: {{ filter.Experience || 'Not defined' }}</span> |
                                                                                <span>Required Position: {{ filter.RequiredPosition || 'Not defined' }}</span> |
                                                                                <span>₹ {{ filter.SalaryRange || 'Not defined' }}</span> |
                                                                                <span><i class="fas fa-map-marker-alt"></i> {{ filter.CityName ||'Location not defined' }}</span>
                                                                            </div>
                                                                            <p class="job-description">
                                                                                <strong>Job Description:</strong> {{ filter.JobDescription || 'No description available' }}
                                                                            </p>
                                                                            <p class="keyskills">
                                                                                <strong>KeySkills:</strong> {{ filter.RequiredSkills || 'Not specified' }}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-lg-4  mmg">
                                                                            <p class="company-name date-button">Job Posted on: {{ filter.CreatedDate | date:'short' }} </p>

                                                                        </div>
                                                                        <div class="col-lg-10 col-sm-6 col-6">
                                                                        </div>
                                                                        <div class="col-lg-2 col-sm-6 col-6">
                                                                            <div class="apply-btn " *ngIf="!appliedPIDs">
                                                                                <button (click)="applujob(filter)">Apply</button>
                                                                            </div>
                                                                            <div class="apply-btn ">
                                                                                <button class="applied " *ngIf="appliedPIDs && appliedPIDs.has(filter.PID)" disabled>Applied</button>
                                                                                <button *ngIf="appliedPIDs && !appliedPIDs.has(filter.PID)" (click)="applujob(filter)">APPLY</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                   
                                                                </div>
                                                               
                                                            </div>

                                                        </div>

                                                       
                                                    </div>
                                                </div>

 


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- end -->

    </div>                           
</div>




