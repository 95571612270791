import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-companyprofile',
  templateUrl: './companyprofile.component.html',
  styleUrls: ['./companyprofile.component.scss']
})
export class CompanyprofileComponent implements OnInit, AfterViewInit, OnDestroy {
    arr: any[];
    Companies: any;
    HomeUrl: any;
    AdsData: any;
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    scrollInterval: any;
    colors: string[] = ['#FFF']; // Add more colors as needed


    constructor(private viewportScroller: ViewportScroller, public router: Router,
        public generalservice: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }


    ngOnInit() {
        this.GetCompanies();
        this.getadds();
    }

    ngAfterViewInit(): void {
        this.startAutoScroll();
    }

    startAutoScroll(): void {
        this.scrollInterval = setInterval(() => {
            const container = this.scrollContainer.nativeElement;
            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                container.scrollTop = 0; // Reset to the top
            } else {
                container.scrollTop += 1; // Scroll down by 1px
            }
        }, 50); // Adjust the interval for smoother or faster scrolling
    }

    ngOnDestroy(): void {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }
    }

    getCardColor(index: number): string {
        return this.colors[index % this.colors.length];
    }

    CompanyProfile() {
        debugger
        this.router.navigate(['/companyinfo']);
    }

    GetCompanies() {
        debugger
        this.arr = [];
        this.arr.push({
            // RID: this.loginDetails[0].RID
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '8');

        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalservice.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Companies = data;
        },
            err => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    navigateToCompanyDetails(RID: any) {
        debugger
        this.router.navigate(['/companyinfo', { RID: RID }]);
    }

    getadds() {
        debugger
        this.arr = [];
        this.arr.push({

        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '5');
        var url = "api/Njobs/NJ_Adds_Crud";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.AdsData = data;
        })

    }


}
