<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item text-white"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Articles</li>
                </ol>
            </nav>
        </div>
    </div><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-4" *ngFor="let item of Articles" (click)="navigateToArticleDetails(item.AID)"> 
                <div class="card space"  >
                    <div class="row">
                        <div class="col-lg-12">
                            <img src="{{HomeUrl + item.ArticleImg}}" class="imgg" alt="Njobs" />
                        </div>
                        <div class="col-lg-12 text-center pt-3">
                            <h6>{{ item.ArticleName }}</h6>
                        </div>
                        <div class="col-lg-12">
                            <p class="p_text">{{ item.Description }}</p>
                        </div>
                        <div class="col-lg-12 pe-4 text-primary text-end">
                            <a>Read More <i class="fa fa-angles-right"></i> </a>
                        </div>
                    </div><br />
                </div>

            </div>
        </div>
    </div>
</div>






