import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2';

//import { CartService } from '../../Services/cart.service'
declare var $: any;


@Component({
    selector: 'app-recuiter-login',
    templateUrl: './recuiter-login.component.html',
    styleUrls: ['./recuiter-login.component.scss']
})
export class RecuiterLoginComponent {


    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;
    @ViewChild('loader') loader: ElementRef | undefined;
    public form: FormGroup;
    arr: any = [];
    isLoggedIn: boolean = false;
    logindata: any;
    logindeta; any;
    Username: any;
    OTP: any;
    Pwd: any;
    HomeURL: any;
    Confirmpassword: any;
    Mobile: any;
    randomnumber: any;
    Password: any;
    public Email: any;
    //  public Password: AbstractControl;
    passwordShow: boolean = false;
    confirmPasswordShow: boolean = false;
    MobileNo: any;
    email: any;
    //    Email: any;
    //  Password: any;
    cartItems: any;
    token: any; otpSent: boolean = false;
    previousUrl: any;
    togglePasswordVisibility(field: string) {
        if (field === 'Password') {
            this.passwordShow = !this.passwordShow;
        } else if (field === 'confirmPassword') {
            this.confirmPasswordShow = !this.confirmPasswordShow;
        }
    }

    constructor(private generalservice: GeneralService, public http: HttpClient, private fb: FormBuilder, private router: Router, private elementRef: ElementRef, private renderer: Renderer2) {
        this.logindeta = localStorage.getItem('Logindetals');
        this.form = this.fb.group({
            Phone1: ['',],
            Password: ['',],
            email: ['',],
        })
        this.email = this.form.controls['email'];
        this.Password = this.form.controls['Password'];

        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
        this.previousUrl = localStorage.getItem("previousUrl");
    }

    ngOnInit() {
        const loginDetails = JSON.parse(localStorage.getItem('LoginDetails') || '{}');
        this.isLoggedIn = loginDetails.isLoggedIn || false;
        this.Username = loginDetails.Username || null;
    }
    openModal() {

    }

    ngAfterViewInit() {
        // Ensure loader is defined before using it

    }


    logout() {

        this.isLoggedIn = false;
        this.Username = null;

        // Clear localStorage
        localStorage.removeItem('LoginDetails');
    }

    Close() {
        const modal4 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal4, 'display', 'none');
        const modal5 = this.elementRef.nativeElement.querySelector('#myModal3');
        this.renderer.setStyle(modal5, 'display', 'none');
        const modal6 = this.elementRef.nativeElement.querySelector('#myModal4');
        this.renderer.setStyle(modal6, 'display', 'none');
    }


    // Methods to show and hide the loader


    public OnSubmit(): void {
        debugger
        // this.showLoader(); // Show loader at the start of the submission process
        this.arr = [];
        this.arr.push({
            Phone1: this.form.value.email,
            PWD: this.form.value.Password,
        });

        if (this.form.valid) {
            var uploadfile = new FormData();
            uploadfile.append("MobileNo", this.form.value.email);
            uploadfile.append("Password", this.form.value.Password);

            var url = "api/Njobs/RecruiterLogin";


            this.generalservice.PostData(url, uploadfile,).then(
                (data: any) => {
                    // Hide loader after the request completes

                    if (data != null) {
                        if (data === "Invalid User") {
                            this.generalservice.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Please enter valid credentials, If you are a new user please sign up', '');
                            this.router.navigate(['/recuiter-register']);
                            this.isLoggedIn = false;
                        } else if (data === "Admin cannot login") {
                            this.generalservice.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Admin cannot login', '');

                            this.isLoggedIn = false;
                        }
                        else {
                            debugger
                            localStorage.setItem("LoginDetails", JSON.stringify(data));
                            console.log('ad', "LoginDetails")
                            const previousUrl = localStorage.getItem('previousUrl'); // Retrieve the stored URL
                            debugger
                            if (previousUrl) {
                                this.router.navigateByUrl(previousUrl).then(() => {
                                    window.location.reload();
                                });
                                //} else if (this.cartItems === undefined || !this.cartItems || this.cartItems.length === 0) {
                                //    this.router.navigateByUrl('/navbar').then(() => {
                                //        window.location.reload();
                                //    });
                            } else {
                                this.router.navigate(['/company-account']).then(() => {
                                    window.location.reload();
                                });
                            }
                        }
                    } else {
                        // Handle the case where data is null
                        this.generalservice.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Please enter valid credentials, If you are a new user please sign up', '');
                        this.router.navigate(['/Signup']);
                        this.isLoggedIn = false;
                    }
                }, (err: any) => {
                    //   this.hideLoader(); // Hide loader if there is an error
                    this.generalservice.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');
                    this.isLoggedIn = false;
                });
        }
    } // Hide loader if there is an error in getting the token





    private encodeBase64(input: string): string {
        return btoa(input); // btoa() encodes a string in Base64
    }

    ForgotPassword() {
        debugger
        this.Email = "";
        this.OTP = "";
        this.Password = "";
        this.Confirmpassword = "";
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'block');
        this.CheckMobile(this.Email);
    }

    CheckMobile(Email) {
        debugger
        Email: Email

        if (Email == undefined || Email == "" || Email == null) {

            //alert("Please Enter EmailID")
            // this.openToast();
        }
        else {

            var UploadFile = new FormData();
            UploadFile.append("Param", Email);
            //UploadFile.append("Flag", "1");
            var url = "api/Njobs/Checking_Mobile"

            this.generalservice.PostData(url, UploadFile).then(data => {

                if (data != 'NOTEXIST') {

                    this.Email = data[0].Email;
                    this.OTP = ""
                    this.MobileNo = Email;
                    this.SendOtpForMobile();
                    this.generalservice.ShowAlert('SUCCESS', 'Email validated successfully. Please enter the OTP sent to your email.', 'success');
                    const modal2 = this.elementRef.nativeElement.querySelector('#myModal2');
                    this.renderer.setStyle(modal2, 'display', 'none');

                    // Show myModal3
                    const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal3, 'display', 'block');

                }
                else {

                    this.generalservice.ShowAlert('WARNING', 'Please enter a valid email address.', 'warning');
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var url = 'api/Njobs/SendOTPtoMail?Email=' + this.Email + '&OTP=' + this.randomnumber + '&Subject=OTP to reset password';
        this.generalservice.GetData(url).then(data => {
            if (data == "SUCCESS") {

            }
            else {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        });
    }


    SendOTPtoMail() {
        debugger
        var Content = "Your OTP is: " + this.OTP;
        var UploadFile = new FormData();
        UploadFile.append("Email", this.MobileNo);
        UploadFile.append("Content", Content);
        var url = "api/Njobs/SendOTPtoMail";
        this.generalservice.PostData(url, UploadFile).then((data: any) => {

        })
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber == otp) {
            const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
            this.renderer.setStyle(modal3, 'display', 'none');

            const modal = this.elementRef.nativeElement.querySelector('#myModal4');
            this.renderer.setStyle(modal, 'display', 'block');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/

    /*Change password*/
    ChangePassword(Password, confirmpassword) {
        debugger
        if (Password == undefined || Password == null || Password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (Password != confirmpassword) {
                alert("password and confirm Password must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Param", this.Password);
                UploadFile.append("Param1", this.Email);
                var url = "api/Njobs/Forgot_Password"

                this.generalservice.PostData(url, UploadFile).then(data => {

                    if (data == 'SUCCESS') {
                        this.generalservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', ' Password Changed Successfully.', '')
                        const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                        this.renderer.setStyle(modal, 'display', 'none');
                    }
                }, err => {
                    const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                    this.renderer.setStyle(modal, 'display', 'none');
                    // this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }


}




