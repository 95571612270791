

<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Job Seeker's Login </li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container   d-flex justify-content-center">
        <div class="row mt-3 mb-5  mobilee">
            <div class="card">
                <div class="col-lg-12 d-flex align-items-center">
                    <div class="card-body">
                        <form action="#" class="sign-in-form" autocomplete="off">
                            <!--<img src="../../../assets/Images/Novuschem_logo.webp" class="novuschemlogo1 logo1">-->
                            <h1 class="title text-center mb-3"> Job Seeker Login </h1>


                            <!--Inserting Email/Mobile and Password-->
                            <label for="username">Mobile/Email</label>
                            <div class="input-field">
                                <i class="fas fa-phone input-icon-phone left"></i>
                                <input [formControl]="email" placeholder="Enter Email" type="text" autocomplete="new-password" class="form-control" />
                            </div>
                         
                            <label for="password">Password*</label>
                            <div class="input-field">
                                <input [formControl]="Password" placeholder="Enter Password" type="{{ passwordShow ? 'text' : 'password' }}" autocomplete="new-password" class="form-control" />
                                <i class="fas fa-lock input-icon-phone left"></i>
                                <span class="toggle-password input-icon-password right" (click)="togglePasswordVisibility('Password')">
                                    <i class="fa fas" [ngClass]="{ 'fa-eye': passwordShow, 'fa-eye-slash': !passwordShow }"></i>
                                </span>
                                <small class="text-danger" *ngIf="form.get('Password')?.touched && form.get('Password')?.hasError('required')">Password is required</small>
                                <small class="text-danger" *ngIf="form.get('Password')?.touched && form.get('Password')?.hasError('minlength')">Password should be at least 6 characters long</small>
                            </div>

                            <input type="submit" value="Login" class="btn solid" (click)="OnSubmit()" />

                            <div class="row">

                                <div class="col-lg-7">
                                    <div class="panels-container">
                                        <div class="panel left-panel">
                                            <div class="content   f14">

                                                Need Account >> <a routerLink="/Registration">
                                                    Sign up
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div>
                                        <a (click)="ForgotPassword()" class="transition forgotpas f14 " style="cursor: pointer;">Forgot password?</a>
                                    </div>
                                </div>
                            </div>


                        </form>
                        <div></div><div></div>
                    </div>
                    <div class="modal" id="myModal2" style="margin-top:9rem;">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header state modal-primary d-flex justify-content-between align-items-center">
                                    <h4 class="modal-title" id="modal-primary-label">Enter Email</h4>
                                    <button type="button" class="close closendsubmit" data-dismiss="modal" aria-label="Close" (click)="Close()">
                                        <span aria-hidden="true"  >X</span>
                                    </button>
                                </div>

                                <div class="modal-body">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>Email<span style="color:red">*</span></label>
                                        <input [(ngModel)]="Email" class="form-control Caps" placeholder="Enter Email" type="text" style="width:450px">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="closendsubmit  submit" (click)="CheckMobile(Email)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->
                    <!-- #region check otp popup for forgot password-->
                    <div class="modal" id="myModal3">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content sss">
                                <div class="modal-header state modal-primary d-flex justify-content-between align-items-center">
                                    <h4   class="modal-title" id="modal-primary-label">Enter Otp</h4>
                                    <button type="button" class="closendsubmit close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true"  >X</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>OTP<span style="color:red">*</span></label>
                                        <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                                    </div>
                                    <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                                        <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="closendsubmit  submit" (click)="VerifyOtp(OTP)">Submit</button>
                                    <!--<button id="closemodal" type="button" data-dismiss="modal"class="" (click)="Close()">Close</button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->
                    <!-- #region update customer and vendor password-->
                    <div class="modal" id="myModal4">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content sss">
                                <div class="modal-header state modal-primary d-flex justify-content-between align-items-center">
                                    <h4  class="modal-title" id="modal-primary-label">Change Password</h4>
                                    <button type="button" class="closendsubmit close" data-dismiss="modal" aria-label="Close" (click)="Close()" ><span aria-hidden="true">X</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>Password<span style="color:red">*</span></label>
                                        <input [(ngModel)]="Password" class="form-control Caps" placeholder="Enter password" type="{{ passwordShow ? 'text' : 'Password' }}" style="width:450px">
                                        <span class="toggle-password1" (click)="togglePasswordVisibility('Password')">
                                            <i class="fa" [ngClass]="{ 'fa-eye': passwordShow, 'fa-eye-slash': !passwordShow }"></i>
                                        </span>
                                    </div>

                                    <br>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>Confirm Password<span style="color:red">*</span></label>
                                        <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirm password" type="{{ confirmPasswordShow ? 'text' : 'Password' }}" style="width:450px">
                                        <span class="toggle-password1" (click)="togglePasswordVisibility('confirmPassword')">
                                            <i class="fa" [ngClass]="{ 'fa-eye': confirmPasswordShow, 'fa-eye-slash': !confirmPasswordShow }"></i>
                                        </span>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="closendsubmit  submit" (click)="ChangePassword(Password,Confirmpassword)">Submit</button>
                                    <!--<button id="closemodal" type="button"class="close1" data-dismiss="modal" (click)="Close()">Close</button>-->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

