<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item text-white"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Companies Details</li>
                </ol>
            </nav>
        </div>
    </div><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-6"  *ngFor="let item of Companies">
                        <div class="card space" (click)="navigateToCompanyDetails(item.RID)">
                            <div class="row">
                                <div class="col-lg-2">
                                    <img src="{{HomeUrl + item.Companylogo}}" class="img_card" />
                                </div>
                                <div class="col-lg-10">
                                    <h6>{{ item.CompanyName }}</h6>
                                </div>
                                <div class="col-lg-12">
                                    <p class="p_text">{{ item.AboutInfo }}</p>
                                </div>
                            </div><br />
                        </div>
                    </div>
                </div>
               

            </div>
            <div class="col-lg-3" >
                <div #scrollContainer class="campus-direct-container no-scrollbar sc_hight fixed-container" style="">
                    <div class="job-card"
                         *ngFor="let item of AdsData; let i = index"
                         [ngStyle]="{ 'background-color': getCardColor(i) }">
                        <img src="{{HomeUrl+item.Image}}" style="border-radius:10px;" /><br />
                        <h3 class="company-name  text"><b>{{ item.CompanyName }}</b></h3>
                        <h6 class="job-title  text-black text">{{ item.JobTitle }}</h6>
                        <p class="text">
                            <span class="text-black font_14 experience"><i class="fa-solid fa-suitcase"></i> {{ item.Experience }} Years</span><br />
                            <span class="text-black font_14 location"><i class="fa-solid fa-location-dot"></i> {{ item.Address }}</span><br />
                            <span class="text-black font_14 job-type"><i class="fa-solid fa-indian-rupee-sign"></i> {{ item.SalaryRange }}</span><br />
                            <span class="text-black font_14 job-type"><i class="fa-solid fa-envelope-open-text"></i> {{ item.Skills }}</span>
                        </p>
                    </div>
                </div>
            </div>

                </div>
            </div>
</div>






