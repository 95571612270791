
<div class="demo-modal-panel">
    <a href="mailto:info@njobs.info" class="sidebar-item">
        <button class="sidebar-demo-control">
            <i class="fa-solid fa-envelope icon"></i>
        </button>
        <span class="button-text">mail</span> <!-- Text outside the button -->
    </a>

    <a href="tel:9000164198" class="sidebar-item">
        <button class="sidebar-demo-control">
            <i class="fa fa-phone icon"></i>
        </button>
        <span class="button-text">call</span> <!-- Text outside the button -->
    </a>

    <a href="https://wa.me/919876543210" class="sidebar-item" target="_blank">
        <button class="sidebar-demo-control">
            <i class="fab fa-whatsapp icon" style="font-size: 17px; font-weight: bold;"></i>
        </button>
        <span class="button-text">WhatsApp</span> <!-- Corrected text to "WhatsApp" -->
    </a>

</div>




<!--<div class="demo-modal-panel">

    <a href="mailto:info@njobs.info">
        <button class="sidebar-demo-control" (click)="toggleClass()">
            <i class="fa-solid fa-envelope top">  </i>
        </button>
    </a>

    <a href="tel:9948485939">
        <button class="sidebar-demo-control" (click)="toggleClass()">
            <i class="fa fa-phone" top></i>
        </button>
    </a>
    <a href="tel:9876543210">
        <button class="sidebar-demo-control" (click)="toggleClass()">
            <i class="fa fa-upload" top></i>
        </button>
    </a>
</div>-->

<!--<div class="example-demo-modal" [class.active]="classApplied">
    <div class="inner">
        <ul>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-1.jpg" alt="image">
                    <span>Main Demo</span>
                    <a href="/" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-2.jpg" alt="image">
                    <span>Slider Demo</span>
                    <a href="/home-two" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-3.jpg" alt="image">
                    <span>Video Demo</span>
                    <a href="/home-three" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-4.jpg" alt="image">
                    <span>Ripple Home</span>
                    <a href="/home-four" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-5.jpg" alt="image">
                    <span>Creative Demo 1</span>
                    <a href="/home-five" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-6.jpg" alt="image">
                    <span>Creative Demo 2</span>
                    <a href="/home-six" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-7.jpg" alt="image">
                    <span>Creative Demo 3</span>
                    <a href="/home-seven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-8.jpg" alt="image">
                    <span>Animation Text Demo</span>
                    <a href="/home-eight" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-9.jpg" alt="image">
                    <span>Particles Demo 1</span>
                    <a href="/home-nine" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-10.jpg" alt="image">
                    <span>Creative Demo 4</span>
                    <a href="/home-ten" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-11.jpg" alt="image">
                    <span>Creative Demo 5</span>
                    <a href="/home-eleven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-12.jpg" alt="image">
                    <span>Gradient Demo</span>
                    <a href="/home-twelve" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-13.jpg" alt="image">
                    <span>Particles Demo 2</span>
                    <a href="/home-thirteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-details.jpg" alt="image">
                    <span>Blog Details</span>
                    <a href="/blog-details" class="link-btn"></a>
                </div>
            </li>
        </ul>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control" (click)="toggleClass()">
            <i class="fas fa-times"></i>
        </button>
        <div class="title">View Demo</div>
    </div>
</div>-->
