 
import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-useraccount',
    templateUrl: './useraccount.component.html',
    styleUrls: ['./useraccount.component.scss']
})
export class UseraccountComponent {
 activeTab: string = 'appliedJobs'; // Default active tab
    LoginDetails: any;
    UserName: any; Email: any;
    MobileNo: any; Address: any;
    KeySkills: any; CuurentLocation: any;
    City: any; State: any; Qualification: any;
    Experience: any; Salary: any; Gender: any;
    showTabContent: boolean = false;
    District: any; arr: any;
    updatedata: any; HomeUrl: any;
    imagefile2: any; ProfileImage: any;
    ProfileImagedata: any; applieddata: any;
    appliedstatus: any; appileddata: any;
    ResumeFile: any; Resumepdf: any; ProfileImg: any;
    Profiledata: any; Profile: any; Designation: any;
    AudioFile: any
    Audiofile: any
    Audiofile1: any
    videoFile: any
    Videofile: any
    Videofile1: any
    arr1: any; ExpectingSalary: any;
    mediadata: any; countdata: any;
    genderOptions = ['Male', 'Female', 'Other'];

    constructor(public generalservice: GeneralService, private http: HttpClient,
        private router: Router) {
        debugger
        this.LoginDetails = JSON.parse(localStorage.getItem("userDetails"));
        this.ProfileImg = JSON.parse(localStorage.getItem("ProfileImage"));
        this.Resumepdf = JSON.parse(localStorage.getItem("Resume"));
        this.UserName = this.LoginDetails.FirstName || '';
        this.Email = this.LoginDetails.Email || '';
        this.MobileNo = this.LoginDetails.MobileNo || '';       
        this.Address = this.LoginDetails.Address || '';       
        this.State = this.LoginDetails.State || '';       
        this.City = this.LoginDetails.City || '';       
        this.District = this.LoginDetails.District || '';       
        this.Experience = this.LoginDetails.Experience || '';       
        this.Salary = this.LoginDetails.Salary || '';       
        this.ExpectingSalary = this.LoginDetails.ExpectingSalary || '';       
        this.Gender = this.LoginDetails ?.Gender || '';    
        this.Designation = this.LoginDetails.Designation || '';       
        this.Qualification = this.LoginDetails.Qualification || '';
        this.CuurentLocation = this.LoginDetails.CurrentWorkLocation || '';
        this.KeySkills = this.LoginDetails.KeySkills || '';
        this.ProfileImage = this.LoginDetails.Photo || '';
        this.ResumeFile = this.HomeUrl + this.LoginDetails.Resume;
        this.AudioFile = this.HomeUrl + this.LoginDetails.AudioRecord;
        this.videoFile = this.HomeUrl + this.LoginDetails.VideoRecord;
       

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //  this.HomeUrl = 'http://localhost:44341/'
        });

    }
     
    ngOnInit() {
        this.getProfile();
        this.appliedjob();
        this.appliedjobstatus();
        this.appliedcount();
        this.getdetailsbased_regid();
    }

    Logout() {
        debugger
        localStorage.clear();
        this.router.navigate(['']);
    }

    setActiveTab(tab: string) {
        debugger
        console.log(`Switching to: ${tab}`); // Log the tab switch
        this.activeTab = tab; // Update the active tab
    }

    //Profile can Updated 
    updateprofile() {
        debugger
        if (!this.KeySkills || !this.Designation || !this.Qualification ||
            !this.Address || !this.State || !this.District || !this.City ||
            !this.CuurentLocation || !this.ExpectingSalary || !this.Salary ||
            !this.Experience) {
            this.generalservice.ShowAlert("ERROR", 'Please give all star Mark Fields', 'error');
            return;
        }

        if (!this.ResumeFile) {
            this.generalservice.ShowAlert("ERROR", "Please upload your resume", "error");
            return; // Exit the function if resume is not uploaded
        }
        this.arr = [];
        this.arr.push({
            RegId: this.LoginDetails.RegId,
            FirstName: this.UserName,
            Email: this.Email,
            MobileNo: this.MobileNo,
            Address: this.Address,
            State: this.State,
            District: this.District,
            City: this.City,
            Qualification: this.Qualification,
            Designation: this.Designation,
            CurrentWorkLocation: this.CuurentLocation,
            KeySkills: this.KeySkills,
            Experience: this.Experience,
            Salary: this.Salary,
            ExpectingSalary: this.ExpectingSalary,
            Gender: this.Gender,
            Resume: this.ResumeFile,
            AudioRecord: this.Audiofile1,            
            VideoRecord: this.Videofile1,

        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/Njobs/Crud_Jobsekar_Registration";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            if (data = 'SUCCESS') {
                this.updatedata = JSON.stringify(this.arr[0]);
                localStorage.setItem("userDetails", this.updatedata);
                this.generalservice.ShowAlert("Update", 'Your Profile Update Succesfully', 'success');
                this.router.navigate(['/job']).then(() => {
                    window.location.reload()
                });
            } else {
                this.generalservice.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error');
            }
           
        })
    }

    selectedFileName: string;
    onFileSelected(event: any) {
        debugger;
        const file: File = event.target.files[0];

        // Check if a file is selected
        if (!file) {
            alert('No file selected.');
            return;
        }

        // Extract the file extension and convert it to lowercase
        const ext = file.name.split('.').pop().toLowerCase();

        // Define allowed file extensions
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif', 'avi', 'mov', 'mp4'];

        // Validate the file type
        if (allowedExtensions.includes(ext)) {
            const formData: FormData = new FormData();
            formData.append('PostedFile', file, file.name);

            // Define the API endpoint for uploading
            const url = 'api/Njobs/UploadProfileImage';

            // Call the service to upload the file
            this.generalservice.PostData(url, formData).then((data: any) => {
                if (data) {
                    this.imagefile2 = this.HomeUrl + data; // Update the image source
                    this.ProfileImage = data; // Store the profile image path
                    this.updateprofileimage();
                } else {
                    alert('Upload failed. Please try again.');
                }
            }).catch(error => {
                console.error('Error uploading file:', error);
                alert('An error occurred while uploading the file. Please try again.');
            });
        } else {
            alert('Only jpg/jpeg, png, avi, mov, and mp4 files are allowed!');
        }
    }
     
    //Jobseeker registration function
    updateprofileimage() {
        if (!this.imagefile2) {
            this.generalservice.ShowAlert("ERROR", 'please select Image', 'error')
            return;
        }
            this.arr = [];
            this.arr.push({
                RegId: this.LoginDetails.RegId,
                Photo: this.ProfileImage,
            })
            var uploadfile = new FormData();
            uploadfile.append("Param", JSON.stringify(this.arr));
            uploadfile.append("Flag", '5');
            var url = "api/Njobs/Crud_Jobsekar_Registration";
            this.generalservice.PostData(url, uploadfile).then(data => {
                if (data == 'SUCCESS') {
                    this.ProfileImagedata = JSON.stringify(this.arr[0]);
                    localStorage.setItem('ProfileImage', this.ProfileImagedata);        
                  //  this.generalservice.ShowAlert("Update", 'Your Profile Updated Succesfully', 'update')
                  //  window.location.reload();
                } else {
                    this.generalservice.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error')
                }

            })

    }


    //Profile Image can updated method
    getProfile() {
        var uploadfile = new FormData();
        uploadfile.append("Param", this.LoginDetails.RegId);
        var url = "api/Njobs/Get_UpdatedProfile_Image";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.Profiledata = data;
            this.Profile = this.Profiledata[0].Photo
        });

    }
    getdetailsbased_regid() {
        debugger   
        var uploadfile = new FormData();
        uploadfile.append("Param", this.LoginDetails.RegId);
       
        var url = "api/Njobs/NJ_Get_Seekerdetails_BasedOn_RegId";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.mediadata = data;
            this.ResumeFile = this.HomeUrl + this.mediadata[0].Resume || '';
            this.videoFile = this.HomeUrl + this.mediadata[0].VideoRecord || '';
            this.AudioFile = this.HomeUrl + this.mediadata[0].AudioRecord || '';

        });

    }
    openpdf() {
        if (this.ResumeFile) {
            // Open the PDF file in a new tab
            window.open(this.ResumeFile, '_blank');
        } else {
            console.error('No resume file found to open.');
            alert('No resume file available to display.');
        }
    }


    //JobSeeker Applications geting method 
    appliedjob() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("SeekerID", this.LoginDetails.RegId);
        var url = "api/Njobs/Get_Seekar_AppliedJobs";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.applieddata = data;
        });
    }

    appliedjobstatus() {
        var uploadfile = new FormData();
        uploadfile.append("R_ID", this.LoginDetails.RegId);
        var url = "api/Njobs/Get_appiledJob_status";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.appliedstatus = data;
        });
    }

    detectFiles3(input2: any) {
        const reader = new FileReader();
        if (input2.files.length) {
            const file = input2.files[0];
            this.selectedFileName = file.name; // Store the file name
            reader.onload = () => {
                this.imagefile2 = reader.result;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile === "pdf" || "jpeg" || "png" || "docx") {
                var UploadFile = new FormData();
                UploadFile.append("PostedFile", file);
                var url = 'api/Njobs/UploadResumeFile';
                this.generalservice.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        this.imagefile2 = this.HomeUrl + data;
                        this.ResumeFile = data;
                        this.ResumeFile = this.ResumeFile;

                    }
                });
            }
            else {
                alert("Allowed file types: pdf, txt");
            }

        }
    }


    //JobSeeker Registration can getting data in Grid
    updateResume() {
        debugger
        this.arr = [];
        this.arr.push({
            RegId: this.LoginDetails.RegId,
            Resume: this.ResumeFile,
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '6');
        var url = "api/Njobs/Crud_Jobsekar_Registration";
        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data == 'SUCCESS') {
                this.ResumeFile = JSON.stringify(this.arr[0]);
                localStorage.setItem('Resume', this.ResumeFile);
                this.generalservice.ShowAlert("Update", 'Your Profile Updated Succesfully', 'update')
                window.location.reload();
            } else {
                this.generalservice.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error')
            }

        })
    }




    //JobSeeker can upload Audio 
    Audio(event: any) {
        const fileInput = event.target;

        // Check if the file input has files
        if (fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            this.selectedFileName = file.name;

            // Check the file extension for supported audio types
            const idxDot = file.name.lastIndexOf('.') + 1;
            const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

            if (extFile === 'mp3' || extFile === 'wav') {
                const audioElement = new Audio();
                audioElement.src = URL.createObjectURL(file);

                audioElement.onloadedmetadata = () => {
                    if (audioElement.duration >120) {
                        alert('Your voice audio is more than 1 min. Please upload a shorter audio.');
                    } else {
                        const reader = new FileReader();
                        reader.onload = () => {
                            this.Audiofile = reader.result as string; // Store audio URL for preview
                        };
                        reader.readAsDataURL(file);

                        // Prepare form data for upload
                        const uploadFile = new FormData();
                        uploadFile.append('PostedFile', file);

                        const url = 'api/Njobs/UploadResumeFile';
                        this.generalservice.PostData(url, uploadFile).then((data: any) => {
                            if (data != null) {
                                this.Audiofile = this.HomeUrl + data; // Set the uploaded file URL
                                this.Audiofile1 = data; // Store the uploaded file reference
                            }
                        });
                    }
                };
            } else {
                alert('Allowed file types: mp3, wav'); // Display error for unsupported types
            }
        } else {
            alert('No file selected. Please choose an audio file.'); // Display error for no file selected
        }
    }


    //Jobseeker Audio can updated method
    updateAudioRecord() {
        this.arr1 = [];
        this.arr1.push({
            RegId: this.LoginDetails.RegId,
            AudioRecord: this.Audiofile1,
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr1));
        uploadfile.append("Flag", '7');
        var url = "api/Njobs/Crud_Jobsekar_Registration";
        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data == 'SUCCESS') {
                 this.generalservice.ShowAlert("Update", 'Your Profile Updated Succesfully', 'update')
                window.location.reload();
            } else {
                this.generalservice.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error')
            }

        })
    }



    //ResumeVideo can uploaded by Jobseeker 
    Video(inputEvent: any) {
        debugger;
        const file = inputEvent.target.files[0];

        if (file) {
            const allowedExtensions = ["mp4", "avi", "mov", "wmv"];
            const extension = file.name.split('.').pop()?.toLowerCase();

            if (allowedExtensions.includes(extension!)) {
                // Create a video element to check the duration
                const videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(file);

                videoElement.onloadedmetadata = () => {
                    // Check if the video duration is within the 30-second limit
                    if (videoElement.duration > 30) {
                        alert("Video duration is more than 30 seconds. Please upload a shorter video.");
                    } else {
                        // Display the video for local preview
                        this.videoFile = URL.createObjectURL(file);

                        // Prepare FormData for upload
                        const uploadData = new FormData();
                        uploadData.append("PostedFile", file);

                        const url = 'api/Njobs/UploadResumeFile';

                        // Call upload service and handle response
                        this.generalservice.PostData(url, uploadData).then(
                            (data: any) => {
                                if (data) {
                                    this.Videofile = this.HomeUrl + data;
                                    this.Videofile1 = data; // Server URL for the video
                                    console.log("Video uploaded successfully:", this.Videofile);
                                } else {
                                    console.error("Upload failed: No data returned from server.");
                                }
                            },
                            (error: any) => {
                                console.error("Video upload error:", error);
                            }
                        );
                    }
                };

                // Handle video loading errors
                videoElement.onerror = () => {
                    alert("Error loading video. Please check the file and try again.");
                };
            } else {
                alert("Allowed file types: mp4, avi, mov, wmv");
            }
        }
    }


    //Video(inputEvent: any) {
    //    debugger
    //    const file = inputEvent.target.files[0];

    //    if (file) {
    //        const allowedExtensions = ["mp4", "avi", "mov", "wmv"];
    //        const extension = file.name.split('.').pop()?.toLowerCase();

    //        if (allowedExtensions.includes(extension!)) {
    //            // Display the video for local preview
    //            this.videoFile = URL.createObjectURL(file);

    //            // Prepare FormData for upload
    //            const uploadData = new FormData();
    //            uploadData.append("PostedFile", file);

    //            const url = 'api/Njobs/UploadResumeFile';

    //            // Call upload service and handle response
    //            this.generalservice.PostData(url, uploadData).then(
    //                (data: any) => {
    //                    if (data) {
    //                        this.Videofile = this.HomeUrl + data;
    //                        this.Videofile1 = data; // Server URL for the video
    //                        console.log("Video uploaded successfully:", this.Videofile);
    //                    } else {
    //                        console.error("Upload failed: No data returned from server.");
    //                    }
    //                },
    //                (error: any) => {
    //                    console.error("Video upload error:", error);
    //                }
    //            );
    //        } else {
    //            alert("Allowed file types: mp4, avi, mov, wmv");
    //        }
    //    }
    //}

     



    //Jobseeker Video can Updated method
    updateVideoRecord() {
        debugger
        this.arr = [];
        this.arr.push({
            RegId: this.LoginDetails.RegId,
            VideoRecord: this.Videofile1 ,
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '8');
        var url = "api/Njobs/Crud_Jobsekar_Registration";
        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data == 'SUCCESS') {
                this.generalservice.ShowAlert("Update", 'Your Profile Updated Succesfully', 'update')
                window.location.reload();
            } else {
                this.generalservice.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error')
            }

        })
    }

    //one JobSeeker howmany Jobs can Apply, that applied Count
    appliedcount() {
        var uploadfile = new FormData();
        uploadfile.append("Count", this.LoginDetails.RegId);
        var url = "api/Njobs/Get_appliedJobs_count";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.countdata = data;
        })
    }


}

