import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GeneralService } from '../app/Services/generalservice.service';
import { UserIdleService } from 'angular-user-idle';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    routerSubscription: any;
    LoginDetails: any;
    signinvalue: any;

    constructor(private router: Router, private location: Location, public generalservice: GeneralService,
        public userIdle: UserIdleService) {
        this.LoginDetails = JSON.parse(localStorage.getItem('userDetails'));
        //setInterval(() => {
        //    //this.ForcedLogout();
        //}, 1000);

    }

    ngOnInit(){
     //   this.recallJsFuntions();
        if (this.signinvalue != "true") {
            //Start watching for user inactivity.
            this.userIdle.startWatching();
            // Start watching when user idle is starting and reset if user action is there.
            this.userIdle.onTimerStart().subscribe(count => {
                var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
                for (let event of eventList) {
                    document.body.addEventListener(event, () => this.userIdle.resetTimer());
                }
            });
            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => {
                this.userIdle.stopWatching();
                localStorage.removeItem("LoginDetails");
                localStorage.removeItem("keepsignin");
                this.router.navigate(['/login']);
                this.generalservice.ShowAlert('INFO', 'Your Session has been expired.', 'info');
            });
        }    


        this.location.subscribe((event) => {
            if (event.pop) {
                this.logoutAndClearLocalStorage();
            }
        });

        const localSessionKey = localStorage.getItem('sessionKey');
        const sessionSessionKey = sessionStorage.getItem('sessionKey');

        if (localSessionKey && !sessionSessionKey) {
            // New tab/window detected
            this.clearSession();
        } else {
            // Set session key for current tab
            sessionStorage.setItem('sessionKey', 'active');
        }


    }

    //recallJsFuntions() {
    //    this.routerSubscription = this.router.events
    //    .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
    //    .subscribe(event => {
    //        this.location = this.router.url;
    //        if (!(event instanceof NavigationEnd)) {
    //            return;
    //        }
    //        window.scrollTo(0, 0);
    //    });
    //}


    logoutAndClearLocalStorage() {
        // Clear any items in local storage
        localStorage.clear();

        // Navigate to the login or home page after logout
        this.router.navigate(['/login']);
    }

    clearSession() {
        // Clear local storage and redirect to login
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    //ForcedLogout() {
    //    if (this.LoginDetails != null) {
    //        var UploadFile = new FormData();
    //        UploadFile.append("Mobile", this.LoginDetails.Mobile);
    //        UploadFile.append("Flag", '3');
    //        var url = "api/LawyerChoices/Job_seekarLogin";
    //        this.generalservice.PostData(url, UploadFile).then((data: any) => {
    //            if (data != this.LoginDetails.TokenID) {
    //                window.location.reload();
    //                localStorage.removeItem('LoginDetails');
    //                this.router.navigate(['/login']).then(() => {
    //                    window.location.reload();
    //                });
    //            }
    //        })
    //    }
    //}



}
