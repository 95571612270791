<div id="contact" class="contact-area ptb-100">
    <div class="container mt-5 ">
        <div class="section-title">
            <h4>Get in <span style="color:#2959cf">Touch</span></h4>
            <h2>We’re Here to <span style="color:#2959cf">Help</span></h2>
            <p>Whether you have questions, need assistance, or just want to say hello, we’d love to hear from you! Reach out and let’s connect.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form d-flex justify-content-center">
                    <!--<form (ngSubmit)="submit()">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                    <label>Name</label>
                    <input  minlength="3" maxlength="50"  name="name" type="text" [(ngModel)]="CustomerName"  class="form-control" id="name">

                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                    <label>Email</label>
                    <input  type="text"  class="form-control" [(ngModel)]="Email" id="email">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                    <label>Subject</label>
                    <input  type="text"  class="form-control" [(ngModel)]="Subject" id="subject">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group mb-3">
                    <label>Phone Number</label>
                    <input  type="text"  class="form-control" [(ngModel)]="Number" id="number">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                    <label>Message</label>
                    <textarea  name="message" id="message" cols="30" rows="4" [(ngModel)]="Message" class="form-control"></textarea>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 message">
                <button type="submit" class=" btn-secondary" >Send Message</button>
            </div>
        </div>
    </form>-->
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.538831100301!2d78.37404949678958!3d17.433905700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93bd18410b0f%3A0x8d7e3fea891858ce!2sT-Hub!5e0!3m2!1sen!2sin!4v1731574166313!5m2!1sen!2sin" width="690" height="450" style="border:0; border-radius: 20px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i> <span>Location:</span>Plot No 1/C, Sy No 83/1, Raidurgam, Knowledge City Rd, panmaktha, Hyderabad, Serilingampalle (M), Telangana 500081
                                </li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:info@njobs.info"><span>Email:</span>info@njobs.info</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:+91 9000164198"><span>Call:</span>+91 9000164198</a></li>
                                <!--<li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
