 <div class="container-fluid topnav  d-none d-lg-block">
    <div class="container">
        <div class="row ml-5">
            <div class="col-lg-6 col-7 fs-12  d-flex align-items-center  justify-content-start  ">
                <nav>
                    <ul class="nav-inline">
                        <!--<li  class="nav-item" *ngIf="!rgid "><span class="nav-link nav-link1" routerLink="/Newjobs" >Jobs</span></li>-->
                        <li class="nav-item" *ngIf="!rgid "><span class="nav-link nav-link1" routerLink="/job">Jobs</span></li>
                        <li class="nav-item" *ngIf="!rgid"><span class="nav-link nav-link1" routerLink="/CompanyProfile">Companies</span></li>
                        <li class="nav-item" *ngIf="!rgid"><span class="nav-link nav-link1">Post a Job </span></li>
                        <li class="nav-item"><span class="nav-link nav-link1" routerLink="/Freelancer">Freelancer </span></li>
                        <!--<li  class="nav-item"><span class="nav-link nav-link1" routerLink="/Subscription">Subscription </span></li>-->
                        <!--<li *ngIf="rgid" class="nav-item"><span class="nav-link nav-link1" routerLink="/company-account">Company Account </span></li>-->
                    </ul>
                </nav>
            </div>
            <div class="col-lg-2 d-none d-lg-block">
            </div>
            <!--<div class="col-lg-2 d-none d-lg-block" >
    </div>-->
            <div class="col-lg-2 d-none d-lg-block text-end border-end">
                <!-- Always show the number -->
                <a *ngIf="!(userDetails1 || LoginDetails)" href="tel:9000123456" target="_blank" class="fs-14">
                    <i class="fa fa-phone"></i> +91 9000123456
                </a>
            </div>

            <div class="col-lg-2 col-5 fs-12 d-flex align-items-center justify-content-end">
                <div class="top d-flex align-items-center ms-2">
                    <!-- Show "Recruiter's Login" button only if not logged in -->
                    <button *ngIf="!userDetails1 && !LoginDetails"
                            class="btn btn-primary text-white custom-btn"
                            (click)="Recuterlogin()">
                        Recruiter's Login
                    </button>
                    <!-- Show the phone number if logged in -->
                    <a *ngIf="userDetails1 || LoginDetails"
                       href="tel:9000123456"
                       target="_blank"
                       class="fs-14 text-decoration-none">
                        <i class="fa fa-phone"></i> +91 9000123456
                    </a>
                </div>
            </div>


        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light bg-light"
     [class.active]="classApplied"
      [ngClass]="{ 'active': isMenuOpen, 'sticky': isSticky }"
>
    <div class="container ">
        <a class="navbar-brand"  (click)="home()">
            <img src="assets/img/images/logoo.png" class="logo_width_nav" alt="NJobs" />
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu"[ngClass]="{'class-name': classApplied}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">

            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngIf="!userDetails1 && !LoginDetails">
                    <span class="nav-link" (click)="login()">Login</span>
                </li>
                <li class="nav-item" *ngIf="!userDetails1 && !LoginDetails">
                    <span class="nav-link" (click)="Registration()">Registration</span>
                </li>

            </ul>
            <div class="nav-link username-container">
                <span style="color:black" class="nav-link username"><span class="hello"></span>{{ userDetails1?.FirstName || (LoginDetails[0]?.RecruiterName || '') }}</span>
                <ul class="dropdown-menu">
                    <li *ngIf="!rgid"><span class="nav-link" (click)="manageaccount()">Manage Account</span></li>
                    <li><span class="nav-link" (click)="Logout()">Logout</span></li>
                </ul>
            </div>


            <ul class="navbar-nav ml-auto for-responsive"  [ngClass]="{'show': isMenuOpen}">
                <li class="nav-item"  (click)="closeMenu()"><span class="nav-link" routerLink="/job">Jobs</span></li>
                <li class="nav-item"  (click)="closeMenu()"><span class="nav-link" routerLink="/CompanyProfile">Companies</span></li>
                <li class="nav-item"  (click)="closeMenu()"><span class="nav-link" routerLink="/Career-insights">Post a Job </span></li>
                <li class="nav-item"  (click)="closeMenu()"><span class="nav-link" routerLink="/Freelancer">Freelancer </span></li>
                <li class="nav-item"  (click)="closeMenu()"><span class="nav-link" (click)="Recuterlogin()">Recruiter's Login </span></li>
                <li class="nav-item"  (click)="closeMenu()" *ngIf="!userDetails1 && !LoginDetails"><span class="nav-link" (click)="login()">login</span></li>
                <li class="nav-item"  (click)="closeMenu()"  *ngIf="!userDetails1 && !LoginDetails"><span class="nav-link" (click)="Registration()">Registration</span></li>
                <li class="nav-item"  (click)="closeMenu()" >
                    <div class="nav-link username-container">
                        <span style="color:black" class="nav-link username"><span class="hello"></span>{{ userDetails1?.FirstName || (LoginDetails[0]?.RecruiterName || '') }}</span>
                        <ul class="dropdown-menu">
                            <li *ngIf="!rgid"><span class="nav-link" (click)="manageaccount()">Manage Account</span></li>
                            <li><span class="nav-link" (click)="Logout()">Logout</span></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
