import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy  } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit, AfterViewInit, OnDestroy {
    experienceOptions: string[] = [];
    skills: string = '';
    location: string = '';
    selectedExperience: string = '';
    CategoryData: any;
    arr: any[];
    RID: any;
    HomeUrl: any;
    CompanyFilter: any;
    Companies: any;
    ArticlesData: any;
    showAll = false;
    loginDet: any; 
    loginDetails: any; userDetails: any; AdsData: any;
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    scrollInterval: any;
    /*colors: string[] = ['#FFCCCC', '#CCFFCC', '#CCCCFF', '#FFFFCC', '#FFCCFF', '#CCFFFF'];*/ // Add more colors as needed
    colors: string[] = ['#fff']; // Add more colors as needed
    Positions: any;

    constructor(private viewportScroller: ViewportScroller, public router: Router,
        public generalservice: GeneralService, public http: HttpClient) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.GetCategory();
        this.GetCompanies();
        this.getarticles();
        this.getadds();
        this.GetCompaniesAndPositions();
        // Populate experience options from 1 to 30 years
        for (let i = 1; i <= 30; i++) {
            this.experienceOptions.push(`${i} ${i === 1 ? 'Year' : 'Years'}`);
        }
    }

    ngAfterViewInit(): void {
        this.startAutoScroll();
    }

    startAutoScroll(): void {
        this.scrollInterval = setInterval(() => {
            const container = this.scrollContainer.nativeElement;
            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                container.scrollTop = 0; // Reset to the top
            } else {
                container.scrollTop += 1; // Scroll down by 1px
            }
        }, 50); // Adjust the interval for smoother or faster scrolling
    }

    ngOnDestroy(): void {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }
    }

    GetCategory() {
        debugger
        var url = "api/Njobs/Get_Company_Catageory_Count";
        this.generalservice.GetData(url).then(data => {
            debugger
            this.CategoryData = data;
        })
    }

    findJobs() {
        debugger
        if (!this.userDetails) {
            this.router.navigate(['/Login']);
            return;
        }
        this.router.navigate(['/job'], {
            queryParams: {
                skills: this.skills,
                location: this.location,
                experience: this.selectedExperience
            }
        });
    }

    JobsFilter(CID:any) {
        debugger
        if (!this.userDetails) {
            this.generalservice.ShowAlert("Alert",'Your not login,Please Login',"alert")
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/job', { CID }]);
        }
    }
    navigateToCompanyDetails(RID: any) {
        if (!this.userDetails) { // Check if the user is not logged in
            this.generalservice.ShowAlert("Alert", 'You are not logged in. Please login.', "alert");
            this.router.navigate(['/Login']); // Navigate to the login page
            return;
        }
        this.router.navigate(['/companyinfo', { RID }]); // Navigate to the company info page
    }

    navigateToCompanyProfile(RID: any) {
        if (!this.userDetails) { // Check if the user is not logged in
            this.generalservice.ShowAlert("Alert", 'You are not logged in. Please login.', "alert");
            this.router.navigate(['/Login']); // Navigate to the login page
            return;
        }
        this.router.navigate(['/CompanyProfile', { RID }]); // Navigate to the company profile page
    }


    PostJobsFilter(RID: any) {
        debugger
        if (!this.userDetails) {
            this.generalservice.ShowAlert("Alert", 'Your not login,Please Login', "alert")
            this.router.navigate(['/recuiter-login']);
            return;
        } else {
            this.router.navigate(['/company-account', { RID }]);
        }
    }

    GetCompanies() {
        debugger
        this.arr = [];
        this.arr.push({
           // RID: this.loginDetails[0].RID
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '8');

        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalservice.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Companies = data;
        },
            err => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    GetCompaniesAndPositions() {
        debugger
        var url = "api/Njobs/Get_CompanysAndPositions_Count";
        this.generalservice.GetData(url).then((data: any) => {
            debugger
            this.Positions = data;
        },
            err => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    //navigateToCompanyDetails(RID: any) {
    //    debugger
    //    this.router.navigate(['/companyinfo', { RID: RID }]);
    //}

    //navigateToCompanies() {
    //    this.router.navigate(['/CompanyProfile']); // Adjust '/companies' to the actual route of your companies page
    //}
    navigateToArticles() {
        this.router.navigate(['/Articles']);
    }
    navigateToArticleDetails(AID: any) {
        debugger
        this.router.navigate(['/Articlesdetails', { AID: AID }]);
    }
    // Navigate to the detailed company profile page
    //navigateToCompanyProfile() {
    //    this.router.navigate(['/CompanyProfile']);
    //}
    uploadresume() {
        if (!this.userDetails) {
            this.router.navigate(['/Login']);
        } else {
            this.router.navigate(['/useraccount']);
        }
    }

    getadds() {
        debugger
        this.arr = [];
        this.arr.push({

        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '5');
        var url = "api/Njobs/NJ_Adds_Crud";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.AdsData = data;
        })

    }

    getarticles() {
        debugger
        this.arr = [];
        this.arr.push({

        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '7');
        var url = "api/Njobs/NJ_Articles_Crud";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.ArticlesData = data;
        })

    }

    getCardColor(index: number): string {
        return this.colors[index % this.colors.length];
    }

    
}
