import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    classApplied = false;
    LoginDetails: any;
    rgid: any;
    isRecruiter: boolean = false;
    userDetails1: any;// Set to true if user is a recruiter
    constructor(private viewportScroller: ViewportScroller, private router: Router) {
        debugger
        this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
        this.userDetails1 = JSON.parse(localStorage.getItem('userDetails'));
        if (this.LoginDetails) {
            this.rgid = this.LoginDetails[0].RID

        } else {
            this.userDetails1 = JSON.parse(localStorage.getItem('userDetails'));

        }

    }


    isMenuOpen = false;
    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }


    toggleClass1() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    navigate(link: string) {
        this.router.navigate([link]);
        this.classApplied = false; // Close the menu after navigation
    }
    ngOnInit() {

    }
    home() {
        debugger
        if (!this.userDetails1 && !this.LoginDetails) {
            this.router.navigate([''])
        }
        else if (this.userDetails1.RoleId==3) {
          
            this.router.navigate([''])
        }
        else if (this.LoginDetails[0].RoleId==2) {
           
            this.router.navigate(['/company-account'])
        }
    }
    Logout() {
        if (this.LoginDetails) {
            localStorage.removeItem('LoginDetails');
            localStorage.removeItem('userDetails');

            this.router.navigate(['']).then(() => {
                window.location.reload();
            })
        }
        else if (this.userDetails1) {
            localStorage.clear();
            this.router.navigate(['']).then(() => {
            window.location.reload();
            });
        }
                
    }

    Logout1() {
        if (this.LoginDetails) {
            localStorage.removeItem('LoginDetails');

            this.router.navigate(['']).then(() => {
                window.location.reload();
            })
        }
        else if (this.userDetails1) {
            localStorage.clear();
            this.router.navigate(['']).then(() => {
                window.location.reload();
            });
        }


    }
    //toggleClass() {
    //    this.classApplied = !this.classApplied;
    //}


  


    toggleClass() {
        this.classApplied = !this.classApplied;  
        this.isMenuOpen = !this.isMenuOpen;
        //if (this.classApplied) {
        //    setTimeout(() => {
        //        this.classApplied = false; 
        //    }, 1500);  
        //}
    }
    closeMenu() {
        this.isMenuOpen = false;
    }

    login() {
        
        this.router.navigate(['/Login']);
    }

    Registration() {
        
        this.router.navigate(['/Registration']);
    }

    Recuterlogin() {
        this.router.navigate(['/recuiter-login']);
    }

    manageaccount() {
        this.router.navigate(['/useraccount'])
    }

}
