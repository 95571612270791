import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-managejobs',
  templateUrl: './managejobs.component.html',
  styleUrls: ['./managejobs.component.scss']
})
export class ManagejobsComponent {
    arr: any;
    loginDet: any;
    loginDetails: any;
    public form: FormGroup;
    Postjobs: any;
    PID: any;
    val: any;
    Pending: any;
    msg: any;
    RID: any;
    Jobtitle: any;
    Skills: any;
    CategoryName: any;
    TypeName: any;
    Languages: any;
    SalaryRange: any;
    StateName: any;
    CityName: any;
    Experience: any;
    Qualification: any;
    JobDescription: any;
    arr2: any = [];
    ApprovalStatus: string;
    ApprovalStatus1: any;
    deptList: any[];
    Qualificationlist: any;
    Experiencelist: any;
    Citylist: any;
    SalaryRangelist: any;
    Skillslist: any;
    Languageslist: any;
    JobTypelist: any;
    Categorylist: any;
    WorkingDomains: any;
    RequiredPosition: any;
    constructor(public generalService: GeneralService, public route: ActivatedRoute, private http: HttpClient, private fb: FormBuilder, public Rm: ReactiveFormsModule, public router: Router) {
        this.PID = this.route.snapshot.paramMap.get("PID");
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)

        this.form = this.fb.group({
            WorkingDomains: ['', Validators.required], // Set up form control for Categoryname with required validation
            CategoryName: ['', Validators.required], // Set up form control for Categoryname with required validation
            RequiredPosition: ['', Validators.required], // Set up form control for Categoryname with required validation
            //StateName: ['', Validators.required], // Set up form control for Categoryname with required validation
            //DistrictName: ['', Validators.required], // Set up form control for Categoryname with required validation
            CityName: ['', Validators.required], // Set up form control for Categoryname with required validation
            TypeName: ['', Validators.required], // Set up form control for Categoryname with required validation
            Languages: ['', Validators.required], // Set up form control for Categoryname with required validation
            SalaryRange: ['', Validators.required], // Set up form control for Categoryname with required validation
            Skills: ['', Validators.required], // Set up form control for Categoryname with required validation
            Experience: ['', Validators.required], // Set up form control for Categoryname with required validation
            Qualification: ['', Validators.required], // Set up form control for Categoryname with required validation
            JobDescription: ['', Validators.required] // Set up form control for Categoryname with required validation
        });
    }

    ngOnInit(): void {
        this.GetManagejobs();
        this.GetCategory();
        this.GetCity();
        this.GetJobType();
        this.GetLanguages();
        this.GetSalaryRange();
        this.GetSkills();
        this.GetExperience();
        this.GetQualification();
    }

    GetManagejobs() {
        debugger
            this.arr = [];
            this.arr.push({
                PID: this.PID
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '5');

            var url = "api/Njobs/NJ_PostJobs_Crud";
            this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Postjobs = data;
            
                //this.RID= this.RID,
                    this.WorkingDomains = this.Postjobs[0].WorkingDomains,
                    this.RequiredPosition = this.Postjobs[0].RequiredPosition,
                    this.Skills = {
                    Skills: this.Postjobs[0].Skills,
                    SKID: this.Postjobs[0].SKID 
                    };
                this.CategoryName = {
                    CategoryName: this.Postjobs[0].CategoryName,
                    CID: this.Postjobs[0].CID
                };
                this.TypeName = {
                    TypeName: this.Postjobs[0].TypeName,
                    JTID: this.Postjobs[0].JTID
                };
                this.Languages = {
                    Languages: this.Postjobs[0].Languages,
                    LID: this.Postjobs[0].LID
                };
                this.SalaryRange = {
                    SalaryRange: this.Postjobs[0].SalaryRange,
                    SID: this.Postjobs[0].SID
                };
                this.CityName = {
                    CityName: this.Postjobs[0].CityName,
                    CityId: this.Postjobs[0].CityId
                };
                this.Experience = {
                    Experience: this.Postjobs[0].Experience,
                    EXPID: this.Postjobs[0].EXPID
                };
                this.Qualification = {
                    Qualification: this.Postjobs[0].Qualification,
                    QID: this.Postjobs[0].QID
                };
                this.JobDescription = this.Postjobs[0].JobDescription
                
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    Update(item) {
        debugger;
        const SKID = this.Skills ? this.Skills.SKID : null;
        const CID = this.CategoryName ? this.CategoryName.CID : null;
        const JTID = this.TypeName ? this.TypeName.JTID : null;
        const LID = this.Languages ? this.Languages.LID : null;
        const SID = this.SalaryRange ? this.SalaryRange.SID : null;
        const CityId = this.CityName ? this.CityName.CityId : null;
        const EXPID = this.Experience ? this.Experience.EXPID : null;
        const QID = this.Qualification ? this.Qualification.QID : null;
        var arr2 = [];
            this.arr2 = [];
            this.arr2.push({
                PID: this.PID,
                RID: this.loginDetails[0].RID,
                WorkingDomains: this.WorkingDomains,
                RequiredPosition: this.RequiredPosition,
                SKID: SKID,
                CID: CID,
                JTID: JTID,
                LID: LID,
                SID: SID,
                CityId: CityId,
                EXPID: EXPID,
                QID: QID,
                JobDescription: this.JobDescription
                });
                var UploadFile = new FormData();
                UploadFile.append('Param', JSON.stringify(this.arr2));
                UploadFile.append("Flag", '2');
                var url = "api/Njobs/NJ_PostJobs_Crud";
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    debugger
                    if (data === 'SUCCESS') {
                        this.generalService.ShowAlert("SUCCESS", 'Your JobPost Updated Successfully', 'success');
                        this.router.navigate(['/company-account']);
                        this.form.reset(); // Reset the form after success
                    } else {
                        this.generalService.ShowAlert("ERROR", 'Something went wrong', 'error');
                    }
                });             
    }

   
    Back() {
        this.router.navigate(['/company-account'])
    }

    GetSkills() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Skills";
        this.generalService.GetData(url).then(data => {

            this.Skillslist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Skillslist.length; i++) {
                let type = this.Skillslist[i];
                if (type.Skills.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetCategory() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Category";
        this.generalService.GetData(url).then(data => {

            this.Categorylist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Categorylist.length; i++) {
                let type = this.Categorylist[i];
                if (type.CategoryName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }



    GetJobType() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_JobTypes";
        this.generalService.GetData(url).then(data => {

            this.JobTypelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.JobTypelist.length; i++) {
                let type = this.JobTypelist[i];
                if (type.TypeName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetLanguages() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Languages";
        this.generalService.GetData(url).then(data => {

            this.Languageslist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle3(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Languageslist.length; i++) {
                let type = this.Languageslist[i];
                if (type.Languages.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetSalaryRange() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_SalaryRange";
        this.generalService.GetData(url).then(data => {

            this.SalaryRangelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle4(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.SalaryRangelist.length; i++) {
                let type = this.SalaryRangelist[i];
                if (type.SalaryRange.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }


    GetCity() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_City";
        this.generalService.GetData(url).then(data => {

            this.Citylist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle5(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Citylist.length; i++) {
                let type = this.Citylist[i];
                if (type.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetExperience() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Experience_Master";
        this.generalService.GetData(url).then(data => {

            this.Experiencelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle6(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Experiencelist.length; i++) {
                let type = this.Experiencelist[i];
                if (type.Experience.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetQualification() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Qualification";
        this.generalService.GetData(url).then(data => {

            this.Qualificationlist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    filterGroupsSingle7(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Qualificationlist.length; i++) {
                let type = this.Qualificationlist[i];
                if (type.Qualification.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

}
   
