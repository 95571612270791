import { Component, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';

@Component({
  selector: 'app-companyinfo',
  templateUrl: './companyinfo.component.html',
  styleUrls: ['./companyinfo.component.scss']
})
export class CompanyinfoComponent {
    loginDet: any;
    loginDetails: any;
    Companies: any;
    arr: any;
    HomeUrl: any;
    RID: any;
    Postjobs: any;
    CompanyName: any;
    applieddata: any;
    appliedPIDs: any;
    constructor(public generalService: GeneralService, public route: ActivatedRoute, private http: HttpClient, private fb: FormBuilder, public Rm: ReactiveFormsModule, public router: Router) {
        this.RID = this.route.snapshot.paramMap.get("RID");
        this.loginDet = localStorage.getItem("userDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit() {
        this.GetCompanies();
        this.GetPostjobs();
        this.appliedjob();
    }

    currentTab: string = 'companyDetail'; // Set default tab to 'companyDetail'

    switchTab(tabName: string): void {
        this.currentTab = tabName; // Change the active tab
    }


    dropdownOpen: boolean = false;

    toggleDropdown(): void {
        this.dropdownOpen = !this.dropdownOpen;
    }

    @HostListener('document:click', ['$event'])
    closeDropdown(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (!target.closest('.dropdown')) {
            this.dropdownOpen = false;
        }
    }

    GetCompanies() {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("Param", this.RID);
        var url = "api/Njobs/Get_CompanyDetails_BasedOn_logo";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Companies = data;
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

     GetPostjobs() {
        debugger
        this.arr = [];
        this.arr.push({
            RID: this.RID
         });
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

        var url = "api/Njobs/NJ_PostJobs_Crud";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.Postjobs = data;
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    //After applying job by JobSeeker, that application is inserting method
    applujob(job: any) {
        debugger
        if (!this.loginDetails) {
            this.generalService.ShowAlert("Alert", 'Your not Login, Please Login and continue for applying jobs', 'Alert');
            this.router.navigate(['/Login']);
            return;
        }
        if (this.loginDetails.ApprovalStatus === 1) {
            this.generalService.ShowAlert("ERROR", 'you do not have permission for applying job. admin need to accept,please contact', 'error');
            return;
        }
        this.arr = [];
        this.arr.push({
            SeekerID: this.loginDetails.RegId,
            P_ID: job.PID,
            R_ID: job.RID,
            ActionStatus: 1,
            AppliedStatus: 1
        })
        var uploadfile = new FormData();
        uploadfile.append("xml", JSON.stringify(this.arr));
        var url = "api/Njobs/Insert_Applied_Job";
        this.generalService.PostData(url, uploadfile).then(data => {
            if (data = "SUCCESS") {
                this.generalService.ShowAlert("SUCCESS", 'You have SuccessFully applied for this job', 'success');
                window.location.reload();
            } else {
                this.generalService.ShowAlert("ERROR", 'Some Thing Went Wrong,Please Try Again Later', 'error');
            }
        })
    }

    //JonSeeker applied Job, that candidates can shown in grid
    appliedjob() {
        debugger
        const seekerID = this.loginDetails ? this.loginDetails.RegId : 1;
        var uploadfile = new FormData();
        uploadfile.append("SeekerID", seekerID);
        var url = "api/Njobs/Get_Seekar_AppliedJobs";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.applieddata = data;

            this.appliedPIDs = new Set(this.applieddata.map(job => job.PID));

          //  this.findJobs();
           // this.getfilterjob();

        });
    }
}
