<div class="pt-100">
    <!--<div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item text-white"><a class="text-white" href="#">Home</a></li>
                 
                    <li class="breadcrumb-item active text-white" aria-current="page">Company Account</li>
                </ol>
            </nav>
        </div>
    </div>-->
    <div class="container mt-5 mb-5 ">
        <div class="row">
            <div class="col-lg-3 col-sm-3  d-flex flex-column  ">
                <div class="profilepic  d-flex flex-column align-items-center border-bottom justify-content-center">
                    <img [src]="cmpnylogo" />
                    <div class="col-lg-8 mt-2">

                        <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="onFileSelected($event)">
                    </div>
                </div>
                <div class="border-bottom mt-4 pb-3 d-flex justify-content-between">
                    <span>
                        <i class="fa-solid fa-envelope me-1 "></i>
                        <b>Email</b>
                    </span>
                    <span>
                        <!--<i class="fa-solid fa-envelope me-1"></i>-->
                        {{Email}}
                    </span>
                </div>
                <div class="border-bottom mt-4 pb-3 d-flex justify-content-between">
                    <span>
                        <i class="fa-solid fa-globe me-1 me-1"></i>

                        <b>Url</b>
                    </span>

                    <span>
                        <!--<i class="fa-solid fa-globe me-1 me-1"></i>-->
                        {{Website}}
                    </span>
                </div>
                <div class="border-bottom mt-4 pb-3 d-flex justify-content-between">
                    <span>
                        <i class="fa-solid fa-location-dot me-1"></i>

                        <b>Location</b>
                    </span>
                    <span>
                        <!--<i class="fa-solid fa-location-dot me-1"></i>-->
                        {{Address}}
                    </span>
                </div>
                <div class="border-bottom mt-4 pb-3 d-flex justify-content-between">
                    <span>
                        <i class="fa-solid fa-address-card me-1"></i>

                        <b>ContactUs</b>
                    </span>

                    <span>
                        <!--<i class="fa-solid fa-address-card me-1"></i>-->
                        {{MobileNo}}
                    </span>

                </div>

            </div>
            <div class="col-lg-9 col-sm-9 account">
                <div class="d-flex justify-content-between align-items-center">
                    <h4><b>Company Account</b> </h4>
                    <!--<span> <button class="logoutbtn">LOGOUT</button></span>-->
                </div>

                <!-- From User Account start-->
                <div class="row">
                    <div class="col-lg-12">

                        <div class="tab-wrap">

                            <input type="radio" id="tab1" name="tabGroup2" class="tab" [checked]="selectedTab === 'tab1'">
                            <label for="tab1">Manage Account</label>

                            <input type="radio" id="tab2" name="tabGroup2" class="tab" [checked]="selectedTab === 'tab2'">
                            <label for="tab2">Post Jobs</label>

                            <input type="radio" id="tab3" name="tabGroup2" class="tab" (click)="Getpendingjob()" [checked]="selectedTab === 'tab3'">
                            <label for="tab3">Candidates Resume </label>

                            <div class="tab__content">
                                <div class="row mt-2">
                                    <div class="col-md-3">
                                        <div class="vertical-tab border" role="tabpanel">
                                            <!-- Nav tabs -->
                                            <ul class="nav nav-tabs" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link text-start border-0"
                                                       [class.active]="currentTab === 'companyDetail'"
                                                       (click)="switchTab('companyDetail')"
                                                       role="tab" data-bs-toggle="tab">Company Detail</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link text-start border-0"
                                                       [class.active]="currentTab === 'subscriptionDetail'"
                                                       (click)="switchTab('subscriptionDetail')"
                                                       role="tab" data-bs-toggle="tab">Subscription Detail</a>
                                                </li>
                                            </ul>
                                        </div>


                                    </div>
                                    <div class="col-md-9 border p-3 rounded-1">
                                        <div class="tab-content tabs">
                                            <!-- Company Detail Tab Content -->
                                            <div role="tabpanel" class="tab-pane fade show"
                                                 [class.active]="currentTab === 'companyDetail'"
                                                 *ngIf="currentTab === 'companyDetail'" id="companyDetail">
                                                <h5>Company Detail</h5>
                                                <hr />

                                                <div class="card-body">
                                                    <div class="row gutters">
                                                        <!--<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <h6 class="mb-2 text-primary">Company Details</h6>
                                                        </div>-->
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="form-group">
                                                                <label for="CompanyEmail">Recruiter Company Name</label>
                                                                <input type="text" class="form-control" id="CompanyName" placeholder="Enter Recruiter Company Name" [(ngModel)]="RecruiterName" readonly>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="form-group">
                                                                <label for="eMail">Company Email</label>
                                                                <input type="email" class="form-control" id="eMail" placeholder="Enter email ID" [(ngModel)]="Email">
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="form-group">
                                                                <label for="phone">Website</label>
                                                                <input type="text" class="form-control" id="Website" placeholder="Enter Website" [(ngModel)]="Website">
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="form-group">
                                                            <label for="fullName">Company Address</label>
                                                            <input type="text" class="form-control" id="Address" placeholder="Enter Address" [(ngModel)]="Address">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-xl-12">
                                                        <div class="form-group">
                                                            <label for="companyAboutInfo" class="text-primary mt-3">Company AboutInfo</label>
                                                            <textarea class="form-control" name="companyAboutInfo" placeholder="Enter Company AboutInfo" rows="5" required [(ngModel)]="AboutInfo"></textarea>
                                                        </div>

                                                    </div>



                                                    <div class="row gutters mt-4">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="text-right d-flex justify-content-end">
                                                                <button type="button" id="submit" name="submit" class="btn btn-danger upload me-2">Cancel</button>
                                                                <button type="button" id="submit" name="submit" class="btn btn-danger upload" (click)="updateprofile()">Update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="tab-content tabs">
                                            <div role="tabpanel" class="tab-pane fade show"
                                                 [class.active]="currentTab === 'subscriptionDetail'"
                                                 *ngIf="currentTab === 'subscriptionDetail'" id="subscriptionDetail">
                                                <h4>Subscription Detail</h4>
                                                <hr />
                                                <!--<h5>Package Details</h5>-->
                                                <div *ngFor="let item of Packagedata">
                                                    <div class="card width-400">
                                                        <h6>{{item.PackageName}}</h6><br />
                                                        <p class="notecont mb-4">{{item.PackageDescription}}</p>
                                                        <p class=" mb-4">{{item.PackageDuration}}</p>
                                                        <p class="notecont mb-3">PRICE:<b>{{item.PackagePrice}}.00</b></p>
                                                        <p class="notecont ">{{item.PaymentMode}}</p>
                                                        <img class="scanner" src="../../../assets/img/ScanerImag.jpeg" />
                                                        <div class="notecont"><b>NOTE: </b>Please Enter Your TransctionID For Quick Verification</div>
                                                        <div>
                                                            <input class="subscription" [(ngModel)]="TransctionID" placeholder="Enter TransctionID" />
                                                            <button class="btn btn-danger submit" (click)="Subscribedd()">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Jobs -->
                            <div class="tab__content">
                                <div class="row mt-3 ">
                                    <div class="col-md-12">
                                        <div class="vertical-tab" role="tabpanel">
                                            <div class="row">
                                                <!-- Left Column: Post Job Tab -->
                                                <div class="col-md-3 d-flex flex-column justify-content-start">
                                                    <div class="nav flex-column nav-tabs border" role="tablist" aria-orientation="vertical">

                                                        <a class="nav-link text-start border-0"
                                                           [class.active]="jobtab === 'Postjobs'"
                                                           (click)="switchjobs('Postjobs')"
                                                           role="tab"
                                                           data-bs-toggle="tab">Post Jobs</a>
                                                        <a class="nav-link active border post-job-tab border-0"
                                                           [class.active]="jobtab === 'Managejobs'"
                                                           (click)="switchjobs('Managejobs')"
                                                           role="tab" data-bs-toggle="tab">Manage Jobs</a>
                                                        <!-- Add more tabs as needed -->
                                                    </div>
                                                </div>
                                                <!-- manage job start-->
                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 small-screen mb-3">
                                                    <div role="tabpanel" class="tab-pane fade show"
                                                         [class.active]="jobtab === 'Managejobs'"
                                                         *ngIf="jobtab === 'Managejobs'" id="Managejobs">

                                                        <h5 class="headd">Manage Jobs</h5><hr>

                                                        <div class="card" *ngFor="let filter of Postjobs">

                                                            <div class="delete">
                                                                <div class="dropdown " (click)="toggleDropdown()">
                                                                    <button class="dropdown-btn">
                                                                        <i class="fa-solid fa-ellipsis-vertical" role="button" aria-haspopup="true"></i>
                                                                    </button>
                                                                    <div class="dropdown-content" [class.show]="isDropdownOpen">
                                                                        <a (click)="Editform(filter.PID)"><i class="fa-solid fa-pen"></i> Edit</a>
                                                                        <a (click)="Delete(filter.PID)"><i class="fa-solid fa-trash-can"></i> Delete </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="job-info">

                                                                <h3>{{ filter.WorkingDomains }} <span>(More Jobs)</span></h3>


                                                                <p class="company-name">{{RecruiterName.RecruiterName }} </p>
                                                                <div class="job-meta">
                                                                    <span>Experience:{{ filter.MExperience || 'Not defined' }}</span> |
                                                                    <span>Required Position: {{ filter.RequiredPosition || 'Not defined' }}</span> |
                                                                    <span>₹ {{ filter.SalaryRange || 'Not defined' }}</span> |
                                                                    <span><i class="fas fa-map-marker-alt"></i> {{ filter.CityName ||'Location not defined' }}</span>
                                                                </div>
                                                                <p class="job-description">
                                                                    <strong>Job Description:</strong> {{ filter.JobDescription || 'No description available' }}
                                                                </p>
                                                                <p class="keyskills">
                                                                    <strong>KeySkills:</strong> {{ filter.RequiredSkills || 'Not specified' }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- manage job end -->
                                                    <!--<div class="col-md-3"></div>-->
                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 small-screen w-100">
                                                        <div role="tabpanel" class="tab-pane fade show"
                                                             [class.active]="jobtab === 'Postjobs'"
                                                             *ngIf="jobtab === 'Postjobs'" id="Postjobs">
                                                            <div class="card h-100 m-0 postjob-table">
                                                                <div class="card-body">
                                                                    <h5>Post Jobs</h5><hr />
                                                                    <!-- personal detail -->
                                                                    <form [formGroup]="form">
                                                                        <div class="row gutters text-primary">
                                                                           
                                                                            <div class="col-lg-12">
                                                                                <div class="form-group">
                                                                                    <label><b>Hiring Company Name<span style="color:red">*</span></b></label>
                                                                                    <input class="form-control" formControlName="CompanyName" type="text" placeholder="Company Name">
                                                                                    <small *ngIf="form.controls.CompanyName.touched && form.controls.CompanyName.errors?.required" class="p-error">Company Name is required.</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="form-group">
                                                                                    <label><b>Job Title<span style="color:red">*</span></b></label>
                                                                                    <input class="form-control" formControlName="Jobtitle" type="text" placeholder="Job Title">
                                                                                    <small *ngIf="form.controls.Jobtitle.touched && form.controls.Jobtitle.errors?.required" class="p-error">Job Title is required.</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="form-group">
                                                                                    <label><b>Required Position<span style="color:red">*</span></b></label>
                                                                                    <input class="form-control" formControlName="RequiredPosition" type="text" placeholder="RequiredPosition" oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                                                                                    <small *ngIf="form.controls.RequiredPosition.touched && form.controls.RequiredPosition.errors?.required" class="p-error">RequiredPosition is required.</small>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <label for="UserName">
                                                                                    Select Skills <span style="color:red">*</span>
                                                                                </label>
                                                                                <div style="display: flex; align-items: center;">
                                                                                    <p-multiSelect [options]="Skillslist"
                                                                                                   formControlName="Skills"
                                                                                                   placeholder="Select Skills"
                                                                                                   optionLabel="Skills"
                                                                                                   display="chip"
                                                                                                   [style]="{ width: '100%' }">
                                                                                    </p-multiSelect>
                                                                                    <div class="circle-plus" (click)="open()">
                                                                                        <i class="fa fa-plus"></i>
                                                                                        <span class="tooltip-text">Add skill</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            <div class="col-lg-12">
                                                                                <label for="category">Category Name<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="CategoryName" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                                                                    field="CategoryName" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select CategoryName" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.CategoryName.touched && form.controls.CategoryName.errors?.required"
                                                                                       class="p-error" style="color:red">CategoryName is required.</small>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <label for="category">Salary Range<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="SalaryRange" [suggestions]="deptList" (completeMethod)="searchCont5($event)"
                                                                                                    field="SalaryRange" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select SalaryRange" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.SalaryRange.touched && form.controls.SalaryRange.errors?.required"
                                                                                       class="p-error" style="color:red">SalaryRange is required.</small>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <label for="category">Type Of Job<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="TypeName" [suggestions]="deptList" (completeMethod)="searchCont2($event)"
                                                                                                    field="TypeName" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select TypeName" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.TypeName.touched && form.controls.TypeName.errors?.required"
                                                                                       class="p-error" style="color:red">TypeName is required.</small>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <label for="category">Languages<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="Languages" [suggestions]="deptList" (completeMethod)="searchCont3($event)"
                                                                                                    field="Languages" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select Languages" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.Languages.touched && form.controls.Languages.errors?.required"
                                                                                       class="p-error" style="color:red">Languages is required.</small>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <label for="category">Experience<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="Experience" [suggestions]="deptList" (completeMethod)="searchCont6($event)"
                                                                                                    field="Experience" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select Experience" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.Experience.touched && form.controls.Experience.errors?.required"
                                                                                       class="p-error" style="color:red">Experience is required.</small>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <label for="category">City Name<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="CityName" [suggestions]="deptList" (completeMethod)="searchCont1($event)"
                                                                                                    field="CityName" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select CityName" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.CityName.touched && form.controls.CityName.errors?.required"
                                                                                       class="p-error" style="color:red">CityName is required.</small>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <label for="category">Qualification Name<span style="color:red">*</span></label>
                                                                                <div class="add_input uomm">
                                                                                    <p-autoComplete formControlName="Qualification" [suggestions]="deptList" (completeMethod)="searchCont7($event)"
                                                                                                    field="Qualification" [dropdown]="true" [size]="90"
                                                                                                    placeholder="Select Qualification" [minLength]="1"
                                                                                                    [style]="{'color': 'black', 'height':'33px'}"
                                                                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                                                                    </p-autoComplete>
                                                                                </div>
                                                                                <small *ngIf="form.controls.Qualification.touched && form.controls.Qualification.errors?.required"
                                                                                       class="p-error" style="color:red">Qualification is required.</small>
                                                                            </div>

                                                                            <div class=" col-12 mt-3 text-primary">
                                                                                <label for="eMail">Job Description<span style="color:red">*</span></label>
                                                                                <div class="form-group col-md-12 mt-2">
                                                                                    <textarea id="comment" name="comment" formControlName="JobDescription" cols="40" rows="5" class="form-control"></textarea>
                                                                                    <small *ngIf="form.controls.JobDescription.touched && form.controls.JobDescription.errors?.required"
                                                                                           class="p-error" style="color:red">JobDescription is required.</small>
                                                                                </div>
                                                                            </div>

                                                                            <div class="row gutters mt-4">
                                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                                        <!--<span class="status-text border border-danger-subtle p-2">Status: Paid</span>-->
                                                                                        <button type="button" id="submit" name="submit" [disabled]="form.invalid" (click)="onSubmit(form.value)" class="btn btn-danger upload">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Applied Candidates Details  -->






                            <div class="tab__content">
                                <div class="row mt-3 ">
                                    <div class="col-md-12">
                                        <div class="vertical-tab" role="tabpanel">
                                            <div class="row">
                                                <!-- Left Column: Post Job Tab -->
                                                <div class="col-md-3 d-flex flex-column justify-content-start">
                                                    <div class="nav flex-column nav-tabs border" role="tablist" aria-orientation="vertical">
                                                        <a class="nav-link active border post-job-tab border-0"
                                                           [class.active]="selecttab === 'pendingDetail'"
                                                           (click)="switchTab1('pendingDetail')"
                                                           role="tab" data-bs-toggle="tab">Pending</a>
                                                        <a class="nav-link text-start border-0  mb-0"
                                                           [class.active]="selecttab === 'selectedDetail'"
                                                           (click)="switchTab1('selectedDetail')"
                                                           role="tab"
                                                           data-bs-toggle="tab">Selected List</a>
                                                        <a class="nav-link text-start mt-0 border-top-0 border-bottom "
                                                           [class.active]="selecttab === 'rejectDetail'"
                                                           (click)="switchTab1('rejectDetail')"
                                                           role="tab"
                                                           data-bs-toggle="tab">Rejected</a>
                                                        <!-- Add more tabs as needed -->
                                                    </div>
                                                </div>
                                                <!-- manage job start-->
                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 small-screen mb-3">
                                                    <div role="tabpanel" class="tab-pane fade show"
                                                         [class.active]="selecttab === 'pendingDetail'"
                                                         *ngIf="selecttab === 'pendingDetail'" id="pendingDetail">

                                                        <h5 class="headd">Pending Candidates</h5><hr>


                                                        <div class="container">
                                                            <div class="row">

                                                                <div class="col-lg-12 " *ngFor="let candidate of jobdata">
                                                                    <div class="card candidate-card">

                                                                        <div class="card-body">
                                                                            <h5 class="card-title" style=" color: #265ac3;  margin-top: -36px;">
                                                                                <a (click)="DeleteCadidates(candidate.JID)"><i class="fa-solid fa-trash-can " style="margin-left: 100%;color: darkred;"></i>  </a>

                                                                                <i class="fas fa-user" style=" color: black;"></i>&nbsp;{{ candidate.FirstName }}

                                                                            </h5><br />



                                                                            <h6 class="card-subtitle mb-2 text-muted">
                                                                                <i class="fas fa-briefcase"></i> {{ candidate.Designation }}
                                                                            </h6>
                                                                            <!--<p class="card-text">
                                                                                <i class="fas fa-envelope"></i> <strong>Email:</strong> {{ candidate.Email }}<br>
                                                                                <i class="fas fa-graduation-cap"></i> <strong>Qualification:</strong> {{ candidate.Qualification }}<br>
                                                                                <i class="fas fa-tools"></i> <strong>Key Skills:</strong> {{ candidate.KeySkills }}<br>
                                                                                <i class="fas fa-map-marker-alt"></i> <strong>Current Location:</strong> {{ candidate.CurrentWorkLocation }}<br>
                                                                                <i class="fas fa-briefcase"></i> <strong>Experience:</strong> {{ candidate.Experience || 'Not defined' }} years<br>
                                                                                <i class="fas fa-money-bill-wave"></i> <strong>Salary:</strong> ₹{{ candidate.Salary || 'Not defined' }}<br>
                                                                                <i class="fas fa-hand-holding-usd"></i> <strong>Expected Salary:</strong> ₹{{ candidate.EexpectingSalary || 'Not defined' }}
                                                                            </p>-->

                                                                            <div class="card-links  d-flex gap-1">
                                                                               
                                                                                <a (click)="downloadResume($event,candidate)"
                                                                                   class="btn btn-primary ">
                                                                                    <i class="fas fa-file-download"></i> View
                                                                                </a>
                                                                                <a *ngIf="HomeURL + candidate.AudioRecord" class="btn btn-info btn-sm" (click)="playAudio(candidate)">
                                                                                    <i class="fas fa-microphone"></i>Audio
                                                                                </a>

                                                                                <!-- Video Preview Button -->
                                                                                <a *ngIf="HomeURL + candidate.VideoRecord" class="btn btn-secondary btn-sm" (click)="playVideo(candidate)">
                                                                                    <i class="fas fa-video"></i> Video
                                                                                </a>
                                                                                
                                                                                    <button type="button" class="btn btn-success btn-sm select-btn" (click)="UpdatestatusSelect(candidate)">
                                                                                        <i class="fas fa-check"></i> Select
                                                                                    </button>
                                                                                    <button type="button" class="btn btn-danger btn-sm reject-btn" (click)="UpdatestatusReject(candidate)">
                                                                                        <i class="fas fa-times"></i> Reject
                                                                                    </button>
                                                                              



                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <!-- manage job end -->
                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 small-screen mb-3 w-100">
                                                        <div role="tabpanel" class="tab-pane fade show"
                                                             [class.active]="selecttab === 'selectedDetail'"
                                                             *ngIf="selecttab === 'selectedDetail'" id="selectedDetail">

                                                            <h5>Selected Candidates</h5><hr />
                                                            <div class="container-fluid">
                                                                
                                                                <div class="row">
                                                                    <div class="col-lg-12 " *ngFor="let candidate of Sjobdata">

                                                                        <div class="card candidate-card">
                                                                            <div class="card-body">
                                                                                <h5 class="card-title" style=" color: #265ac3;  margin-top: -36px;">
                                                                                    <a (click)="DeleteCadidates(candidate.JID)"><i class="fa-solid fa-trash-can " style="margin-left: 100%;color: darkred;"></i>  </a>

                                                                                    <i class="fas fa-user" style=" color: black;"></i>&nbsp;{{ candidate.FirstName }}

                                                                                </h5><br />
                                                                                <h6 class="card-subtitle mb-2 text-muted">

                                                                                    <i class="fas fa-briefcase"></i> {{ candidate.Designation }}
                                                                                </h6>
                                                                                <!--<p class="card-text">
                                                                                    <i class="fas fa-envelope"></i> <strong>Email:</strong> {{ candidate.Email }}<br>
                                                                                    <i class="fas fa-graduation-cap"></i> <strong>Qualification:</strong> {{ candidate.Qualification }}<br>
                                                                                    <i class="fas fa-tools"></i> <strong>Key Skills:</strong> {{ candidate.KeySkills }}<br>
                                                                                    <i class="fas fa-map-marker-alt"></i> <strong>Current Location:</strong> {{ candidate.CurrentWorkLocation }}<br>
                                                                                    <i class="fas fa-briefcase"></i> <strong>Experience:</strong> {{ candidate.Experience || 'Not defined' }} years<br>
                                                                                    <i class="fas fa-money-bill-wave"></i> <strong>Salary:</strong> ₹{{ candidate.Salary || 'Not defined' }}<br>
                                                                                    <i class="fas fa-hand-holding-usd"></i> <strong>Expected Salary:</strong> ₹{{ candidate.EexpectingSalary || 'Not defined' }}
                                                                                </p>-->
                                                                                
                                                                                <div class="card-links">
                                                                                   
                                                                                      <!-- Resume Download Button -->
                                                                                    
                                                                                    <a (click)="downloadResume($event,candidate)"
                                                                                       class="btn btn-primary btn-sm">
                                                                                        <i class="fas fa-file-download"></i> View
                                                                                    </a>
                                                                                    <a *ngIf="HomeURL + candidate.AudioRecord" class="btn btn-info btn-sm" (click)="playAudio(candidate)">
                                                                                        <i class="fas fa-microphone"></i>Audio
                                                                                    </a>

                                                                                    <!-- Video Preview Button -->
                                                                                    <a *ngIf="HomeURL + candidate.VideoRecord" class="btn btn-secondary btn-sm" (click)="playVideo(candidate)">
                                                                                        <i class="fas fa-video"></i> Video
                                                                                    </a>
                                                                                    <button type="button" class="btn btn-success btn-sm select-btn">
                                                                                        <i class="fas fa-check"></i> Selected
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 small-screen mb-3 w-100 ">
                                                        <div role="tabpanel" class="tab-pane fade show"
                                                             [class.active]="selecttab === 'rejectDetail'"
                                                             *ngIf="selecttab === 'rejectDetail'" id="rejectDetail">
                                                            <h5>Rejected Candidates</h5><hr />
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 m-0 " *ngFor="let candidate of Rjobdata">
                                                                        <div class="card candidate-card">
                                                                            <div class="card-body">
                                                                                <h5 class="card-title" style=" color: #265ac3;  margin-top: -36px;">
                                                                                    <a (click)="DeleteCadidates(candidate.JID)"><i class="fa-solid fa-trash-can " style="margin-left: 100%;color: darkred;"></i>  </a>

                                                                                    <i class="fas fa-user" style=" color: black;"></i>&nbsp;{{ candidate.FirstName }}

                                                                                </h5><br />
                                                                                <h6 class="card-subtitle mb-2 text-muted">
                                                                                    <i class="fas fa-briefcase"></i> {{ candidate.Designation }}
                                                                                </h6>
                                                                                <!--<p class="card-text">
                                                                                    <i class="fas fa-envelope"></i> <strong>Email:</strong> {{ candidate.Email }}<br>
                                                                                    <i class="fas fa-graduation-cap"></i> <strong>Qualification:</strong> {{ candidate.Qualification }}<br>
                                                                                    <i class="fas fa-tools"></i> <strong>Key Skills:</strong> {{ candidate.KeySkills }}<br>
                                                                                    <i class="fas fa-map-marker-alt"></i> <strong>Current Location:</strong> {{ candidate.CurrentWorkLocation }}<br>
                                                                                    <i class="fas fa-briefcase"></i> <strong>Experience:</strong> {{ candidate.Experience || 'Not defined' }} years<br>
                                                                                    <i class="fas fa-money-bill-wave"></i> <strong>Salary:</strong> ₹{{ candidate.Salary || 'Not defined' }}<br>
                                                                                    <i class="fas fa-hand-holding-usd"></i> <strong>Expected Salary:</strong> ₹{{ candidate.EexpectingSalary || 'Not defined' }}
                                                                                </p>-->
                                                                               
                                                                                <div class="card-links">
                                                                                 
                                                                                      <!-- Resume Download Button -->
                                                                                   
                                                                                    <a (click)="downloadResume($event,candidate)"
                                                                                       class="btn btn-primary btn-sm">
                                                                                        <i class="fas fa-file-download"></i> View
                                                                                    </a>
                                                                                    <a *ngIf="HomeURL + candidate.AudioRecord" class="btn btn-info btn-sm" (click)="playAudio(candidate)">
                                                                                        <i class="fas fa-microphone"></i>Audio
                                                                                    </a>

                                                                                    <!-- Video Preview Button -->
                                                                                    <a *ngIf="HomeURL + candidate.VideoRecord" class="btn btn-secondary btn-sm" (click)="playVideo(candidate)">
                                                                                        <i class="fas fa-video"></i> Video
                                                                                    </a>
                                                                                    <button type="button" class="btn btn-danger btn-sm reject-btn">
                                                                                        <i class="fas fa-times"></i> Reject
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- extracontent -->
                                <div class="tab__content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="vertical-tab" role="tabpanel">
                                                <ul class="nav nav-tabs border" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link active   border-0" aria-controls="companyDetail" role="tab">Pending</a>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link border-0 " aria-controls="subscriptionDetail" role="tab" data-bs-toggle="tab">Approved</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-10">
                                            <div class="candidate-list" *ngFor="let item of jobdata">
                                                <div class="candidate-list-box card">
                                                    <div class="py-2 mx-0">
                                                        <div class="align-items-center m-0 row">
                                                            <div class="col-auto">
                                                                <div class="candidate-list-images" *ngIf="item.Photo">
                                                                    <img src="{{HomeURL +  item.Photo}} " alt="" class="avatar-md img-thumbnail rounded-circle" />
                                                                </div>
                                                                <!--<div class="candidate-list-images" *ngIf="!item.Photo">
                                                                    <a href="#"><img src="../assets/img/adam.jpg" alt="" class="avatar-md img-thumbnail rounded-circle" /></a>
                                                                </div>-->
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="candidate-list-content mt-3 mt-lg-0">
                                                                    <p class="text-primary mb-2">{{item.FirstName}}</p>
                                                                    <ul class="list-inline mb-0 text-black">
                                                                        <li class="list-inline-item"><i class="mdi mdi-map-marker"></i> {{item.Designation}}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 d-flex justify-content-end  ms-4">
                                                                <div class="d-flex flex-column align-items-end">
                                                                    <!-- Keep items aligned to the end -->
                                                                    <div class="d-flex justify-content-end mb-2">
                                                                        <!-- Adjusted margin -->
                                                                        <button class="btn btn-primary me-3 mb-5 pb-5" (click)="download(item.Resume)">
                                                                            <i class="fa-solid fa-download"></i> Download CV
                                                                        </button>





                                                                        <span>
                                                                            <i class="fa-regular fa-clock"></i> {{ item.CreatedDate | date: 'MMM d, y, h:mm a' }}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="rounded d-flex justify-content-between align-items-center mt-2">
                                                            <ul class="list-unstyled d-flex text-secondary mb-0">
                                                                <li class="border p-2 mx-2 rounded-4">{{item.KeySkills}}</li>

                                                            </ul>
                                                            <button class="btn btn-success rounded-0 widthh me-4">Selected</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />


                                                <!-- end -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- user account end -->

                </div>
            </div>
        </div>
        <!--<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>-->

    </div>
</div>
<!-- Popup for adding new skill -->
<div class="modal" id="myModal2" style="margin-top:9rem;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary d-flex justify-content-between align-items-center">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Add skills</h4>
                <button type="button" class="close closendsubmit" data-dismiss="modal" aria-label="Close" (click)="Close()">
                    <span aria-hidden="true">X</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>Skills<span style="color:red">*</span></label>
                    <input [(ngModel)]="Rskills" class="form-control Caps" placeholder="Enter skills" type="text" style="width:450px">
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="closendsubmit  submit" (click)="addSkill(Rskills)">Submit</button>
                <!--<button id="closemodal"class="  submit" type="button" data-dismiss="modal" (click)="Close()">Close</button>-->
            </div>
        </div>
    </div>
</div>


<style>
    .circle-plus {
        width: 30px; /* Adjust size as needed */
        height: 30px;
        background-color: #007bff; /* Set the background color */
        color: white;
        border-radius: 50%; /* Makes it a circle */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px; /* Adjust icon size as needed */
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s;
    }

        .circle-plus:hover {
            background-color: #0056b3; /* Darker shade on hover */
        }

    .tooltip-text {
        visibility: hidden;
        width: 80px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        position: absolute;
        bottom: 60px; /* Position above the icon */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 12px;
    }

    .circle-plus:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

</style>
