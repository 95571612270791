export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAgxS0BLto8octkR3zRBFX90iu7rxnN9Vk",
        authDomain: "njobs-870db.firebaseapp.com",
        projectId: "njobs-870db",
        storageBucket: "njobs-870db.firebasestorage.app",
        messagingSenderId: "223046450689",
        appId: "1:223046450689:web:4431fc52ba96a5c0c40a00",
        measurementId: "G-4NR5BNF3TW",
        vpaidKey: "BPqNLYzQeiwtHWpsAwqfI2QCvVu8TnEbCx5Ul0hkpmKvWDHNCwBHbEPqDZXlXmnC1WUQINkxDRRKDEhX95OOxRk"

    }
};
