<div class="ptb-60">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center mleft">
            <div class="row">
                <div class="col-lg-9">
                    <div class="search-container d-none d-lg-block">
                        <div class="search-bar">
                            <input type="text" [(ngModel)]="skills" class="search-input input_radius fs-14 radius" placeholder="Skills, Job Title">
                            <input type="text" [(ngModel)]="location" class="search-input fs-14" placeholder="Location">
                            <select [(ngModel)]="selectedExperience" class="experience-dropdown fs-14">
                                <option value="">Exp</option>
                                <option *ngFor="let option of experienceOptions" [value]="option">{{ option }}</option>
                            </select>
                            <button class="find-jobs-btn" (click)="findJobs()">Find Jobs</button>
                        </div>  
                    </div> 
                    <div class="search-container d-lg-none">
                        <div class="search-bar">
                            <input type="text" [(ngModel)]="skills" class="search-input input_radius fs-14" placeholder="Skills, Job Title">
                            <input type="text" [(ngModel)]="location" class="search-input fs-14" placeholder="Location">
                            <select [(ngModel)]="selectedExperience" class="experience-dropdown fs-14">
                                <option value="">Exp</option>
                                <option *ngFor="let option of experienceOptions" [value]="option">{{ option }}</option>
                            </select>
                            <button class="find-jobs-btn" (click)="findJobs()"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 d-none d-lg-block">
                    <div class="upload-container  mtop">
                        <a (click)="uploadresume()" class="upload-resume-btn"><i class="fa-solid fa-upload"></i> Upload Your Resume</a>
                    </div>
                </div>
            </div>
        </div>
    </div><br />
    <!-- filter -->
    <div class="container">
        <div class="row">
            <!-- filter start -->
            <button class="filter-toggle-btn d-lg-none" (click)="toggleFilter()">
                <i class="fas fa-sliders-h"></i>
            </button>
            <div class="col-lg-3  col-sm-3 border p-3 mb-3 rounded-2" [class.active]="isFilterActive">
                <h3 class="filter-title"><i class="fas fa-filter"></i> ALL FILTERS</h3>
                <div class="filter-section">
                    <h4>Companies</h4>
                    <ul class="filter-list">
                        <li *ngFor="let item of careerdata">
                          <input type="checkbox" id="company1"  [checked]="selectedCompany === item.CompanyName"
                             (change)="onCompanySelect(item.CompanyName)">
                          <label style="cursor: pointer;" for="company1" (click)="Companyfilter(item.CompanyName)">{{item.CompanyName}}</label>
                        </li>
                    </ul>
                    <!--<a href="#" class="view-more">View more</a>-->
                </div>

                <div class="filter-section">
                    <h4>Specialization</h4>
                    <ul class="filter-list" >
                        <li *ngFor="let item of specalizationdata">
                           <input type="radio" id="specialization1" [checked]="SelectedDomain === item.WorkingDomains"
                                  (change)="onSelectWorking(item.WorkingDomains)">
                           <label for="specialization1" >{{item.WorkingDomains}}</label>
                        </li>
                    </ul>
                    <!--<a href="#" class="view-more">View more</a>-->
                </div>                          

                <div class="filter-section">
                    <h4>Experience</h4>
                    <ul class="filter-list" >
                        <li *ngFor="let item of Experiencedata">
                        <input type="radio" name="experience" id="experience1" [checked]="SelectedYear === item.Experience"
                               (change)="onSelectedyear(item.Experience)">
                        <label for="experience1">{{item.Experience}}</label></li>
                     </ul>
                </div>
            </div>
            <div class="filter-overlay" [class.active]="isFilterActive" (click)="closeFilter()"></div>

            <!-- filter end -->
            <!-- post job start -->
            <div class="col-lg-9 col-sm-9">
                <div class="job-container">

                    <div class="job-header" *ngIf="!filterCompany && !filterjobsdata && !Domainfilter">
                        <h2>{{ paginatedJobs ? paginatedJobs.length : 0 }}&nbsp; Jobs out of  {{jobs ? jobs.length : 0}} </h2>
                       
                        <div class="sort-options">
                            <span>Sort by freshness: Last {{ timeGap }}</span>
                        </div>
                    </div>

                    <div class="job-listing" *ngIf="!filterjobsdata && !filterCompany && !Domainfilter">
                        <div *ngIf="jobs && jobs.length > 0; else noJobsFound">
                            <div *ngFor="let job of paginatedJobs" class="job-card">
                                <div class="job-info">
                                    <h3>{{ job.WorkingDomains }} <span>(More Jobs)</span></h3>
                                    <p class="company-name"> {{ job.CompanyName }} </p>
                                    <p class="company-name"><b>Posted by:</b> {{ job.RecruiterName }} </p>
                                    
                                    <div class="job-meta">
                                        <span>Required Position:{{job.RequiredPosition || 'Not defined'}}</span> |
                                        <span>Experience:{{ job.MExperience || 'Not defined' }}</span> |
                                        <span>₹ {{ job.SalaryRange || 'Not defined' }}</span> |
                                        <span><i class="fas fa-map-marker-alt"></i> {{ job.CityName || 'Location not defined' }} | {{ job.TypeName}}</span>
                                    </div>
                                    <p class="job-description">
                                        <strong>Job Description:</strong> {{ job.JobDescription || 'No description available' }}
                                    </p>
                                    <p class="keyskills">
                                        <strong>KeySkills:</strong> {{ job.RequiredSkills || 'Not specified' }}, {{ job.MantatorySkills || 'Not specified' }}
                                    </p>
                                </div>
                                <div class="apply-btn" *ngIf="!appliedPIDs">
                                    <button (click)="applujob(job)">Apply</button>
                                </div>
                                <div class="apply-btn">
                                    <button  class="applied" *ngIf="appliedPIDs && appliedPIDs.has(job.PID)" disabled>Applied</button>
                                    <button *ngIf="appliedPIDs && !appliedPIDs.has(job.PID)" (click)="applujob(job)">APPLY</button>
                                </div>

                            </div>
                        </div>
                        <!-- Display this when there are no jobs -->
                        <ng-template #noJobsFound>
                            <p class="no-jobs-message">No jobs found for the selected criteria.</p>
                        </ng-template>

                        <!-- Pagination Controls -->
                        <div class="paginat">
                            <button (click)="previousPage()" [disabled]="currentPage === 1"><<<</button>{{currentPage}}
                            <button (click)="nextPage()" [disabled]="currentPage >= totalPages">>>></button>
                        </div>

                    </div>

                    <!-- Career Jobs Filter -->

                    <div class="job-header" *ngIf="filterjobsdata && !filterCompany && !Domainfilter">
                        <h2>{{ filterjobsdata ? filterjobsdata.length : 0 }}&nbsp;&nbsp; Job Found on {{CategoryName}} </h2>
                        <div class="sort-options">
                            <span>Sort by freshness: Last {{ timeGap }}</span>
                        </div>
                    </div>

                    <!-- Inserting WorkingDomain, CompanyName, Salaryrange, Experiance -->
                    <div class="job-listing" *ngIf="filterjobsdata && !filterCompany && !Domainfilter">
                        <div *ngIf="filterjobsdata && filterjobsdata.length > 0; else noJobsFound">
                            <div *ngFor="let filter of CategoryFilter" class="job-card">
                                <div class="job-info">
                                    <h3>{{ filter.WorkingDomains }} <span>(More Jobs)</span></h3>
                                    <p class="company-name">{{ filter.CompanyName }} </p>
                                    <p><b>Posted by: </b>{{ filter.RecruiterName }}</p>
                                    <div class="job-meta">
                                        <span>Required Position:{{ filter.RequiredPosition || 'Not defined' }}</span> |
                                        <span>Experience:{{ filter.MExperience || 'Not defined' }}</span> |
                                        <span>₹ {{ filter.SalaryRange || 'Not defined' }}</span> |
                                        <span><i class="fas fa-map-marker-alt"></i> {{ filter.CityName || 'Location not defined' }} | {{ filter.TypeName}}</span>
                                    </div>
                                    <p class="job-description">
                                        <strong>Job Description:</strong> {{ filter.JobDescription || 'No description available' }}
                                    </p>
                                    <p class="keyskills">
                                        <strong>KeySkills:</strong> {{ filter.RequiredSkills || 'Not specified' }}, {{ filter.MantatorySkills || 'Not specified' }}
                                    </p>
                                </div>
                                <div class="apply-btn" *ngIf="!appliedPIDs">
                                    <button (click)="applujob(filter)">Apply</button>
                                </div>
                                <div class="apply-btn">
                                    <button style="background-color:#2257c3" *ngIf="appliedPIDs && appliedPIDs.has(filter.PID)" disabled>Applied</button>
                                    <button *ngIf="appliedPIDs && !appliedPIDs.has(filter.PID)" (click)="applujob(filter)">APPLY</button>
                                </div>

                            </div>
                        </div>
                        <!-- Display this when there are no jobs -->
                        <ng-template #noJobsFound>
                            <p class="no-jobs-message">No jobs found for the selected criteria.</p>
                        </ng-template>

                        <!-- Pagination Controls -->

                        <div class="paginat">
                            <button (click)="previousPage()" [disabled]="currentPage === 1"><<<</button>{{currentPage}}
                            <button (click)="nextPage()" [disabled]="currentPage >= totalPages">>>></button>
                        </div>

                    </div>

                    <!--  Career Jobs Filter End -->
                    <!-- Filterd Company Jobs-->

                    <div class="job-header" *ngIf="filterCompany ">
                        <h2>{{ filterCompany ? filterCompany.length : 0 }}&nbsp;&nbsp; Job Found on {{CompanyName}} </h2>
                        <div class="sort-options">
                            <span>Sort by freshness: Last {{ timeGap }}</span>
                        </div>
                    </div>

                    <div class="job-listing" *ngIf="filterCompany ">
                        <div *ngIf="filterCompany && filterCompany.length > 0; else noJobsFound">
                            <div *ngFor="let filter of filterCompany" class="job-card">
                                <div class="job-info">
                                    <h3>{{ filter.WorkingDomains }} <span>(More Jobs)</span></h3>
                                    <p class="company-name">{{ filter.CompanyName }} </p>
                                    <p>Posted by: {{ filter.RecruiterName }}</p>
                                    <div class="job-meta">
                                        <span>Required Position:{{ filter.RequiredPosition || 'Not defined' }}</span> |
                                        <span>Experience:{{ filter.MExperience || 'Not defined' }}</span> |
                                        <span>₹ {{ filter.SalaryRange || 'Not defined' }}</span> |
                                        <span><i class="fas fa-map-marker-alt"></i> {{ filter.CityName || 'Location not defined' }} | {{ filter.TypeName}}</span>
                                    </div>
                                    <p class="job-description">
                                        <strong>Job Description:</strong> {{ filter.JobDescription || 'No description available' }}
                                    </p>
                                    <p class="keyskills">
                                        <strong>KeySkills:</strong> {{ filter.RequiredSkills || 'Not specified' }}, {{ filter.MantatorySkills || 'Not specified' }}
                                    </p>
                                </div>
                                <div class="apply-btn" *ngIf="!appliedPIDs">
                                    <button (click)="applujob(filter)">Apply</button>
                                </div>
                                <div class="apply-btn">
                                    <button style="background-color:#2257c3" *ngIf="appliedPIDs && appliedPIDs.has(filter.PID)" disabled>Applied</button>
                                    <button *ngIf="appliedPIDs && !appliedPIDs.has(filter.PID)" (click)="applujob(filter)">APPLY</button>
                                </div>
                            </div>
                        </div>
                        <!-- Display this when there are no jobs -->
                        <ng-template #noJobsFound>
                            <p class="no-jobs-message">No jobs found for the selected criteria.</p>
                        </ng-template>
                    </div>

                    <!-- Filter Company Jobs -->

                </div>
            </div>

        </div>
    </div>
</div>
        <!-- end -->
         
