import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
    TransctionID: any;
    arr: any = [];
    Subscribedata: any;
    loginDetails: any;
    loginDet: any;
    Packagedata: any;

    constructor(public generalService: GeneralService, private router: Router) {

        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
    }

    ngOnInit() {
        this.getPackages();

    }

    getPackages() {
        debugger
        this.arr = [];
        this.arr.push({

        })

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '4');
        var url = "api/Njobs/NJ_PackageCRUD"
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.Packagedata = data;
        })

    }


    Subscribedd() {
        debugger
        if (!this.TransctionID) {
            this.generalService.ShowAlert("ERROR", 'Some thing went wrong', 'error');
            return;
        }
        this.arr = [];
        this.arr.push({
            RID: this.loginDetails[0].RID,
            TransctionID: this.TransctionID,
        });
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '10');
        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.Subscribedata = data;
            if (data == 'SUCCESS') {
                this.TransctionID = '';
                this.generalService.ShowAlert("SUCCESS", 'Your TransctionId Submited.Please wait admin will conform your Transction', 'success');
            }
            else {
                this.generalService.ShowAlert("ERROR", 'Some thing went wrong.Please try again later', 'error');
            }
        });
    }


    home() {
        this.router.navigate(['/']);
    }

}
