
<div class="ptb-100">
    <div class="container-fluid bg-secondary p-3 d-flex align-items-center">
        <div class="container d-flex align-items-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Recruiter Signup</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container d-flex justify-content-center">
        <div class="row mt-3 mb-5 mobilee">
            <div class="card">
                <div class="col-lg-12 d-flex align-items-center">
                    <div class="card-body">
                        <h1 class="title text-center">Recruiter Signup</h1>
                        <br />
                        <form (ngSubmit)="registration()" #signupForm="ngForm">
                            <div class="form-group">
                                <label for="username">Recruiter Company Name</label>
                                <input type="text" placeholder="Enter Your Recruiter Company Name" [(ngModel)]="RecruiterName"
                                       id="username" name="username" inputmode="text"
                                       class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label for="mobile">Mobile Number*</label>
                                <input type="text"
                                       id="mobile"
                                       name="mobile"
                                       class="form-control"
                                       placeholder="Enter Your Mobile Number"
                                       [(ngModel)]="Mobile"
                                       maxlength="10"
                                       (input)="validateMobile()"
                                       (mouseenter)="CheckMobile()"
                                       pattern="[6-9][0-9]{9}"
                                       required>
                                <div *ngIf="mobileError" class="text-danger">{{ mobileError }}</div>
                                <span style="color:red" *ngIf="checkNo">Mobile Number Already Exists</span>
                            </div>
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" placeholder="Enter Your Email" [(ngModel)]="Email"
                                       id="email" name="email" inputmode="text"
                                       class="form-control" required>
                            </div>
                           
                                <div class="form-group">
                                    <label for="password">Password*</label>
                                    <div class="input-container position-relative">
                                        <input [type]="showPassword ? 'text' : 'password'"
                                               placeholder="Enter Your Password"
                                               [(ngModel)]="Password"
                                               id="password"
                                               name="password"
                                               class="form-control"
                                               required
                                               pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$"
                                               #passwordInput="ngModel">
                                        <span class="input-icon position-absolute"
                                              style="right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"
                                              (click)="togglePasswordVisibility()">
                                            <i class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                        </span>
                                    </div>
                                    <small *ngIf="passwordInput.invalid && passwordInput.dirty" style="color:red">
                                        Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one alphanumeric character, and one number.
                                    </small>
                                </div>
                         

                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button class="btn btn-primary" type="submit">SIGN UP</button>
                                    </div>
                                    <div class="col-lg-12 text-center mt-3">
                                        <a href="/ForgotPassword" class="text-muted">Already have an account?</a>
                                        &nbsp; <a href="/Login" class="text-muted join-us">Sign in now>></a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

