import { Component,OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { Window } from 'selenium-webdriver';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    HomeUrl: any;
    FirstName: string = '';
    Mobile: string = '';
    Email: string = '';
    Password: string = '';
    arr: any;
    logindetails: any;
    mobileError: string | null = null;
    emailError: string | null = null;
    passwordError: string | null = null;
    showPassword: boolean = false;
    checkNo: any; mobleexist: any;

    constructor(private router: Router, public generalservice: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit(): void {

    }

    // Validate mobile number - exactly 10 digits, starting with 6, 7, 8, or 9
    validateMobile(): boolean {
        const mobilePattern = /^[6-9][0-9]{9}$/; // 6, 7, 8, or 9 at the start, and 10 digits total
        if (this.Mobile && !mobilePattern.test(this.Mobile)) {
            this.mobileError = 'Mobile number must be exactly 10 digits and start with 6, 7, 8, or 9';
            return false;
        } else {
            this.mobileError = null;
            return true;
        }
    }

    // Validate email format
    validateEmail(): boolean {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email pattern
        if (this.Email && !emailPattern.test(this.Email)) {
            this.emailError = 'Please enter a valid email address';
            return false;
        } else {
            this.emailError = null;
            return true;
        }
    }

    // Validate password (basic length check)
    validatePassword(): boolean {
        debugger
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/;  // Regex for at least one lowercase, one uppercase, and one number
        if (this.Password.length < 6) {
            this.passwordError = 'Password must be at least 6 characters long';
            return false;
        } else if (!passwordPattern.test(this.Password)) {
            this.passwordError = "Your password must contain at least one uppercase letter, one lowercase letter, and one numeric value.";
            return false;
        } else {
            this.passwordError = null;
            return true;
        }
    }

    // Check if the entire form is valid before submission
    isFormValid(): boolean {
        return this.validateMobile() && this.validateEmail() && this.validatePassword();
    }

    // Registration process
    registration() {
        debugger
        localStorage.clear();
        //if (!this.Mobile || !this.FirstName ?.trim() || !this.Email ?.trim()) {
        //    this.generalservice.ShowAlert("ERROR", 'Please give all star mark fields correctly', 'error');
        //    return;
        //}
        if (!this.isFormValid()) {
            this.generalservice.ShowAlert("ERROR", 'Please fill all fields correctly', 'error');
            return;
        }
        if (this.mobleexist === 'Mobile Exists') {
            this.generalservice.ShowAlert("ERROR", 'Your Mobile Number Already Exists,Please Login', 'error');
            this.router.navigate(['/Login']);
            return;
        }
     
        this.arr = [];
        this.arr.push({
            FirstName: this.FirstName,
            MobileNo: this.Mobile,
            Email: this.Email,
            Password: this.Password
        });

        const uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", "1");

        const url = "api/Njobs/Crud_Jobsekar_Registration";
        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data === 'SUCCESS') {
                this.login();  // Call login after successful registration
                this.generalservice.ShowAlert("SUCCESS", 'Your Registration Completed Successfully', 'success');
            } else {
                this.generalservice.ShowAlert("ERROR", 'Something went wrong', 'error');
            }
        });
    }

    // Login process
    login() {
        const uploadfile = new FormData();
        uploadfile.append("MobileNo", this.Mobile);
        uploadfile.append("Password", this.Password);

        const url = "api/Njobs/Job_seekarLogin";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.logindetails = JSON.stringify(data[0]);
            localStorage.setItem('userDetails', this.logindetails);
          //  this.generalservice.ShowAlert("SUCCESS", 'Your Registration Completed Successfully,please update your Profile', 'success');
            this.router.navigate(['/useraccount']).then(() => {
                window.location.reload();
            });// Redirect after login
        });
    }

    // Toggle password visibility
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    CheckMobile() {
        var uploadfile = new FormData();
        uploadfile.append("Param1", '1');
        uploadfile.append("Param2", this.Mobile);
        var url = "api/Njobs/Check_Mobile_Email_AlredyExists";
        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data == 'Mobile Exists') {
                this.mobleexist = data;
                this.checkNo = 'Mobile Number Already Exists'
            } else {
                this.checkNo == 11
            }
        })

    }
    //Passwrod validation
    validate(password : string) {
        debugger
        const alphanumeric = /^[a-zA-Z0-9]+$/;
        if (!alphanumeric.test(password)) {
            this.generalservice.ShowAlert("ERROR", 'Password Should be only Alpha Numeric values', 'error');
            return false;
        }
        return true;
    }



}




