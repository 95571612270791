import { Component } from '@angular/core';

@Component({
  selector: 'app-careerinsights',
  templateUrl: './careerinsights.component.html',
  styleUrls: ['./careerinsights.component.scss']
})
export class CareerinsightsComponent {

}
