<div class="ptb-100">
    <!--<div class="container">
        <div class="row">
            <div class="col-lg4"></div>
            <div class="col-lg4">
                <img src="../../../assets/img/images/Comingsoon.png" />
            </div>
            <div class="col-lg-4"></div>
        </div>
    </div>-->
    <div class="container-fluid carousel">
        <div class="container">
            <div class="row">
                <div class="col-6 col-sm-6 pt-5 mt-5">
                    <h1 class="text-white line-gap" style="color: #fff!important;">Valuable guidance for career paths, skill development, and growth opportunities.</h1><br />
                    <h6 class="text-white">Technology enabling machines to simulate human intelligence, perform tasks, and improve through experience and data.</h6>
                </div>
                <div class="col-6 col-sm-6  d-flex justify-content-end ">
                    <img src="../../../assets/img/laptop.png" alt="laptop" class="img-fluid top " />
                </div>
            </div>
        </div>
    </div><br>
    <!-- main-content-second-start -->
    <div class="container-fluid hero-section">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-3">
                    <div class="img-container">
                        <img src="../../../assets/img/ai1.jpg" alt="ai" class="program-image">
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="ps-lg-4">
                        <h1 class="title">Navigating the Next Five Years with AI</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                Artificial Intelligence (AI) is no longer just a buzzword but a transformative force shaping every facet of our lives and work.
                            </p>
                            <p class="mb-4">
                                As we look ahead to the next five years, AI's influence on career landscapes will be both profound and multifaceted.
                            </p>
                            <p>
                                Here’s what professionals should anticipate and how they can thrive in an AI-driven world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hero-section1">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-9 order-2 order-md-1">
                    <div class="ps-lg-4">
                        <h1 class="title">Emerging Roles and Skills Demand</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                AI will continue to drive the creation of new job roles, ranging from AI specialists and data scientists to AI ethicists and automation strategists.
                            </p>
                            <p class="mb-4">
                                Expertise in areas like machine learning, natural language processing, and ethical AI will be in high demand.
                            </p>
                            <p>
                                Upskilling and reskilling will be essential for those aiming to remain competitive, with a focus on both technical proficiencies and complementary soft skills such as critical thinking, adaptability, and ethical reasoning.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 order-1 order-md-2">
                    <div class="img-container">
                        <img src="../../../assets/img/role.jpg" alt="ai" class="program-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hero-section">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-3">
                    <div class="img-container">
                        <img src="../../../assets/img/github-co-pilot.png" alt="AI as a Co-Pilot" class="program-image">
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="ps-lg-4">
                        <h1 class="title">AI as a Co-Pilot, Not a Replacement</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                The narrative that AI will take over all jobs is oversimplified. While AI will automate routine tasks, it will also enhance human capabilities by serving as a "co-pilot."
                            </p>
                            <p class="mb-4">
                                Professionals will collaborate with AI tools, making their work more efficient and accurate.
                            </p>
                            <p>
                                The future workforce must embrace this partnership, leveraging AI to boost creativity, streamline operations, and make data-driven decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hero-section1">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-9  order-2 order-md-1">
                    <div class="ps-lg-4">
                        <h1 class="title">Redefining Traditional Industries</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                AI's impact is not confined to tech. Sectors like healthcare, finance, education, and manufacturing will be reshaped by AI innovations.
                            </p>
                            <p class="mb-4">
                                For example, AI-driven diagnostics will revolutionize medicine, while AI algorithms can personalize education for millions of students.
                            </p>
                            <p>
                                Those who adapt to these shifts, by integrating AI know-how within their industry expertise, will find unique opportunities to lead.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 order-1 order-md-2">
                    <div class="img-container">
                        <img src="../../../assets/img/traditional.jpg" alt="traditional" class="program-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hero-section">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-3">
                    <div class="img-container">
                        <img src="../../../assets/img/regulation.jpg" alt="regulation" class="program-image">
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="ps-lg-4">
                        <h1 class="title">Ethics and AI Regulation Take Center Stage</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                As AI systems become more integrated into our daily lives, ethical considerations and regulations will intensify.
                            </p>
                            <p class="mb-4">
                                Professionals with a background in AI ethics, data privacy, and regulatory compliance will play critical roles in shaping policies and ensuring responsible AI use.
                            </p>
                            <p>
                                Understanding the societal implications of AI will be just as important as technical proficiency.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hero-section1">
        <div class="container">
            <div class="row align-items-center gy-4">
                <div class="col-lg-9 order-2 order-md-1 ">
                    <div class="ps-lg-4">
                        <h1 class="title">AI and the Human Element</h1>
                        <div class="content-text">
                            <p class="mb-4">
                                Ultimately, human skills—like emotional intelligence, empathy, communication, and leadership—will gain even greater significance.
                            </p>
                            <p class="mb-4">
                                AI excels at processing data, but it cannot replicate human connection, intuition, and creativity.
                            </p>
                            <p>
                                Professionals who blend technical expertise with a strong human touch will find themselves indispensable in the workplace of the future.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 order-1 order-md-2">
                    <div class="img-container ">
                        <img src="../../../assets/img/human.jpg" alt="human" class="program-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
