
<div class="container-fluid bg-breadcrum p-3 d-flex align-items-center">
    <div class="container d-flex align-items-center ">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">User Manager</li>
            </ol>
        </nav>
    </div>
</div>

<!--Inserting  FirstName and Designation-->
<div class="container mt-5 mb-5 ">
    <div class="row">
        <div class="col-sm-3  d-flex flex-column  ">
            <div class="profilepic  d-flex flex-column align-items-center border-bottom justify-content-center">
                <img [src]="imagefile2 ? imagefile2 : HomeUrl + this.Profile " />
                <div class="col-lg-8 mt-2">
            
                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="onFileSelected($event)">
                </div>

                <h4 class="mt-4">{{this.LoginDetails.FirstName}}</h4>
                <p>{{this.LoginDetails.Designation}}</p>
            </div>

            <div class="row  mt-5   job-status d-flex  justify-content-between">
                <div class="col-sm-10" *ngFor="let item of countdata">
                    <div class=" border  d-flex flex-column justify-content-start align-items-center bg-light ">
                        <i class="fa-solid fa-suitcase circle mt-5 mb-3 "></i>
                        <p class="text-center mb-2">Applied Jobs</p>
                        <p class="text-center fs-1">{{item.appledcount}}</p>
                    </div>
                </div>

            </div>
            <div class="row gutters mt-5">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card h-100">

                    </div>
                </div>
            </div>

        </div>
        <div class="col-sm-9 account">
            <div class="d-flex justify-content-between align-items-center">
                <!--<h4><b>Manage Your Account</b> </h4>-->
                <h4><b>Profile Settings</b> </h4>
            </div>

            <div class="col-lg-12">

                <div class="container p_0">

                    <div class="tab-wrap">

                        <input type="radio" id="tab1" name="tabGroup2" class="tab" checked>
                        <label for="tab1">Profile</label>

                        <input type="radio" id="tab2" name="tabGroup2" class="tab">
                        <label for="tab2">Applied Jobs</label>

                        <!--<input type="radio" id="tab3" name="tabgroup2" class="tab">
                        <label for="tab3">upload resume</label>-->

                        <!--profile-->
                        <div class="tab__content">

                            <div class="card-body">
                                <div class="row gutters">
                                    <div class="col-12 ">
                                        <h6 class="mb-2 mt-3 text-primary">Personal Details</h6>
                                    </div>
                                    <!-- Full Name and Email in the same row -->
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group">
                                            <label for="fullName">Full Name<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="fullName" placeholder="Enter full name" [(ngModel)]="UserName">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group">
                                            <label for="eMail">Email<span class="valids">*</span></label>
                                            <input type="email" class="form-control" id="eMail" placeholder="Enter email ID" [(ngModel)]="Email">
                                        </div>
                                    </div>
                                    <!-- Phone and Gender in the same row -->
                                    <div class="col-md-6  mt-2 ">
                                        <div class="form-group">
                                            <label for="phone">Phone<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="phone" placeholder="Enter phone number" [(ngModel)]="MobileNo" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <label for="Gender" style="font-size:13px; font-weight:400">Gender<span class="valids">*</span></label>
                                        <div class="form-group d-flex">
                                            <div *ngFor="let option of genderOptions" class="form-check me-3">
                                                <input type="radio" [id]="option" name="gender" class="form-check-input" [(ngModel)]="Gender" [value]="option" />
                                                <label class="form-check-label gender-option" [for]="option">{{ option }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Address Details -->
                                <div class="row gutters mt-2">
                                    <div class="col-12">
                                        <h6 class="mt-3 mb-2 text-primary">Address</h6>
                                    </div>
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group">
                                            <label for="Street">Current Address<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="Street" placeholder="Enter Street" [(ngModel)]="Address">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group">
                                            <label for="sTate">State<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="sTate" placeholder="Enter State" [(ngModel)]="State">
                                        </div>
                                    </div>
                                    <div class="col-md-6  mt-2 ">
                                        <div class="form-group">
                                            <label for="District">District<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="District" placeholder="Enter District" [(ngModel)]="District">
                                        </div>
                                    </div>
                                    <div class="col-md-6  mt-2 ">
                                        <div class="form-group">
                                            <label for="ciTy">City<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="ciTy" placeholder="Enter City" [(ngModel)]="City">
                                        </div>
                                    </div>
                                </div>

                                <!-- Qualification -->
                                <div class="row gutters mt-2">
                                    <div class="col-12">
                                        <h6 class="mt-3 mb-2 text-primary">Qualification</h6>
                                    </div>
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group">
                                            <label for="Qualification">Highest Qualification<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="Qualification" placeholder="Enter Highest Qualification" [(ngModel)]="Qualification">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2 mt-md-0">
                                        <div class="form-group ">
                                            <label for="Experience">Work Experience<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="Experience" placeholder="Enter Experience" [(ngModel)]="Experience" />
                                        </div>
                                    </div>
                                </div>

                                <!-- KeySkills and Designation -->
                                <div class="row gutters">
                                    <div class="col-md-6 mt-2 ">
                                        <div class="form-group">
                                            <label for="KeySkills">KeySkills<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="KeySkills" placeholder="Enter Key Skills" [(ngModel)]="KeySkills" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2 ">
                                        <div class="form-group">
                                            <label for="Designation">Designation<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="Designation" placeholder="Enter Designation" [(ngModel)]="Designation" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Salary Information -->
                                <div class="row gutters">
                                    <div class="col-md-6 mt-2">
                                        <div class="form-group">
                                            <label for="Salary">Current Salary Range<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="Salary" placeholder="Enter salary in rupees(ex:300000)" [(ngModel)]="Salary" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <div class="form-group">
                                            <label for="ExpectingSalary">Expected Salary Range<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="ExpectingSalary" placeholder="Enter salary in rupees(ex:500000)" [(ngModel)]="ExpectingSalary" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Additional Information -->
                                <div class="row gutters">
                                    <div class="col-md-6 mt-2">
                                        <div class="form-group">
                                            <label for="CuurentLocation">Current Work Location<span class="valids">*</span></label>
                                            <input type="text" class="form-control" id="CuurentLocation" placeholder="Enter Current Location" [(ngModel)]="CuurentLocation" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <!--<label for="ResumeFile">Resume</label>
                                        <p style="color:red">Note: Please upload only Pdf  </p>
                                        <input type="file" class="form-control" id="ResumeFile" [(ngModel)]="ResumeFile" (change)="detectFiles3(input2)" accept="pdf/*" #input2 />
                                        <img src="../../../assets/video/pdf.gif" style="width:50px;cursor:pointer" matTooltip="Open PDF" (click)="openpdf()" />-->
                                    </div>
                                </div>

                                <!-- Upload Audio -->
                                <div class="col-12">
                                    <h6 class="mt-3 mb-2 text-primary">Media</h6>
                                </div>
                                <div class="row gutters ">
                                    <div class="col-md-6 mb-3">
                                        <label for="ResumeFile" style="font-size:13px; font-weight:400">Resume<span class="valids">*</span></label>
                                        <p style="color:red">Note: Please upload only Pdf  </p>
                                        <input type="file" class="form-control" id="ResumeFile" [(ngModel)]="ResumeFile" (change)="detectFiles3(input2)" accept="pdf/*" #input2 />
                                        <img src="../../../assets/video/pdf.gif" style="width:50px;cursor:pointer" matTooltip="Open PDF" (click)="openpdf()" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="AudioFile" style="font-size:13px; font-weight:400">Upload Audio</label>
                                        <p style="color:red">Note: Please upload Audio only for 30 sec</p>
                                        <div>
                                            <audio *ngIf="AudioFile" [src]="AudioFile" controls style="width: 100%; margin-top: 10px;">
                                                Your browser does not support the audio tag.
                                            </audio>

                                            <input style="width:100%" type="file" id="customFile" (change)="Audio($event)" [(ngModel)]="AudioFile"
                                                   accept=".mp3, .wav" class="form-control m-img-upload-btn mt-n1 txtDate input" />
                                        </div>

                                    </div>
                                </div>

                                    <!-- Upload Video -->
                                    <div class="row gutters ">
                                        <div class="col-md-6">
                                            <label for="videoFile" style="font-size:13px; font-weight:400">Video</label>
                                            <p style="color:red">Note: Please upload video only for 30 sec</p>
                                            <video *ngIf="videoFile || Videofile" [src]="videoFile || videoFile" controls preload="metadata" style="width: 100%; height: auto; margin-top: 10px;">
                                                Your browser does not support the video tag.
                                            </video>
                                            <input type="file" class="form-control" id="videoFile" [(ngModel)]="videoFile" (change)="Video($event)" accept="Mp4/*" />
                                        </div>
                                    </div>

                                    <!-- Update Button -->
                                    <div class="row gutters mt-4">
                                        <div class="col-12 text-right">
                                            <button type="button" class="btn btn-danger me-2">Cancel</button>
                                            <button type="button" class="btn btn-primary" (click)="updateprofile()">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="tab__content">
                            <div class="row ">
                                <div class="col-md-12 p_0">
                                    <div class="vertical-tab" role="tabpanel">

                                        <div class="tab-content tabs">
                                            <!--Applied Jobs Tab Content-->
                                            <div class="tab-pane fade show active">
                                                <h3>Applied Jobs</h3>
                                                <hr />
                                                <div *ngFor="let item of applieddata">
                                                    <span><b> {{item.WorkingDomains}}</b></span><br /><br />
                                                    <p><b>{{item.CompanyName}} </b> | <b>Required Position:</b>{{item.RequiredPosition || 'Not defined'}}</p>
                                                    <p><b>Experience : </b>{{item.MExperience || 'Not defined'}} | {{item.SalaryRange || 'Not defined'}}</p>
                                                    <p><span><!--<i class="fas fa-map-marker-alt"></i>-->  {{item.CityName}}</span> |<span>{{item.CreatedDate | date:'dd-MM-yyyy'}}</span></p>
                                                    <p><b>Job Discription : </b>{{item.JobDescription || 'No description available'}}</p>
                                                    <button class="btn btn-danger upload mt-3">{{item.StatusName}}</button>                                                   
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div class="tab__content">
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <div class="vertical-tab" role="tabpanel">

                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link"
                                                   [class.active]="activeTab === 'resume'"
                                                   (click)="setActiveTab('resume')">Upload resume</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link"
                                                   [class.active]="activeTab === 'audio'"
                                                   (click)="setActiveTab('audio')">Upload Audio</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link"
                                                   [class.active]="activeTab === 'video'"
                                                   (click)="setActiveTab('video')">Upload Video</a>
                                            </li>
                                        </ul>

                                        <div class="tab-content tabs">
                                            Applied Jobs Tab Content
                                            <div *ngIf="activeTab === 'resume'" class="tab-pane fade show active">
                                                <h3>Resume</h3>
                                                <hr />
                                                <label class="form-label" for="customFile">Upload Resume</label>
                                                <div>

                                                    <input style="width:75%" type="file" id="customFile" [(ngModel)]="ResumeFile" (change)="detectFiles3(input2)"
                                                           accept="pdf/*" #input2 class="form-control m-img-upload-btn mt-n1 txtDate input" />
                                                    <img src="../../../assets/video/pdf.gif" style="width:50px;cursor:pointer" matTooltip="Open PDF" (click)="openpdf()" />
                                                    <br />
                                                    <button type="button" id="submit" name="submit" class="btn btn-danger upload" (click)="updateResume()">submit</button>

                                                </div>
                                            </div>

                                            <div *ngIf="activeTab === 'audio'" class="tab-pane fade show active">
                                                <h3>Audio</h3>
                                                <hr />
                                                <label class="form-label" for="customFile">Upload Audio</label>
                                                <p style="color:red">Note: Please upload Audio only for 30 sec  </p>

                                                <div>
                                                    <audio *ngIf="AudioFile" [src]="Audiofile || AudioFile" controls style="width: 100%; margin-top: 10px;">
                                                        Your browser does not support the audio tag.
                                                    </audio>

                                                    <input style="width:75%" type="file" id="customFile" [(ngModel)]="AudioFile" (change)="Audio(input2)"
                                                           accept="mp3/*" #input2 class="form-control m-img-upload-btn mt-n1 txtDate input" />
                                                    <button type="button" id="submit" name="submit" class="btn btn-danger upload" (click)="updateAudioRecord()">n-submitto</button>

                                                </div>
                                            </div>

                                            <div *ngIf="activeTab === 'video'" class="tab-pane fade show active">
                                                <h3>Video</h3>
                                                <hr />
                                                <label class="form-label" for="customFile">Upload Video</label>
                                                <p style="color:red">Note: Please upload video only for 30 sec  </p>
                                                <video *ngIf="videoFile || Videofile"
                                                       [src]="videoFile || videoFile"
                                                       controls
                                                       preload="metadata"
                                                       style="width: 100%; height: auto; margin-top: 10px;">
                                                    Your browser does not support the video tag.
                                                </video>

                                                <div>

                                                    <input style="width: 75%"
                                                           type="file"
                                                           id="customFile"
                                                           [(ngModel)]="videoFile"
                                                           accept="Mp4/*"
                                                           (change)="Video($event)"
                                                           class="form-control m-img-upload-btn mt-1 txtDate input" /><br />

                                                    <button type="button" id="submit" name="submit" class="btn btn-danger upload" (click)="updateVideoRecord()">submit</button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
            </div>
        </div>
    </div>
</div>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

<div class="container">
    <div class="row">

    </div>
</div>
