import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    userDetails: any;

    constructor(private viewportScroller: ViewportScroller, public generalservice: GeneralService,
        private router: Router) {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    }

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

    ngOnInit() {

    }


    JobsFilter(CID: any) {
        debugger
        if (!this.userDetails) {
            this.generalservice.ShowAlert("Alert", 'Your not login,Please Login', "alert")
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/job', { CID }]);
        }
    }



}
