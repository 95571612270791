import { Component } from '@angular/core';

@Component({
  selector: 'app-jobsnew',
  templateUrl: './jobsnew.component.html',
  styleUrls: ['./jobsnew.component.scss']
})
export class JobsnewComponent {

}
